export const GENERIC_ERROR = "GENERIC_ERROR";
export const FIELD_REQUIRED = "FIELD_REQUIRED";
export const INVALID_EMAIL_ADDRESS = "INVALID_EMAIL_ADDRESS";
export const INVALID_URL = "INVALID_URL";
export const INVALID_CREDENTIALS = "INVALID_CREDENTIALS";
export const INVALID_GENERIC = "INVALID_GENERIC";
export const INVALID_IP = "INVALID_IP";
export const INVALID_UNION_ELEMENT = "INVALID_UNION_ELEMENT";
export const INVALID_UUID = "INVALID_UUID";
export const REQUEST_FAILED = "REQUEST_FAILED";
export const PASSWORDS_MISMATCH = "PASSWORDS_MISMATCH";
export const INVALID_PASSWORD = "INVALID_PASSWORD";
export const INVALID_PASSWORD_SHORT = "INVALID_PASSWORD_SHORT";
export const LENGTH_MIN = "LENGTH_MIN";
export const LENGTH_MAX = "LENGTH_MAX";
export const LENGTH_EQUAL = "LENGTH_EQUAL";
export const MISSING_CLIENT_CREDENTIALS = "MISSING_CLIENT_CREDENTIALS";
export const INVALID_HEX_COLOR = "INVALID_HEX_COLOR";
export const TOO_MANY_REQUESTS = "TOO_MANY_REQUESTS";
export const TOO_MANY_REQUESTS_PLURAL = "TOO_MANY_REQUESTS_plural";
export const TOO_MANY_REQUESTS_SHORT_TIME = "TOO_MANY_REQUESTS_SHORT_TIME";
export const OFFLINE = "OFFLINE";
export const PATTERN_MISMATCH = "PATTERN_MISMATCH";
export const INVALID_DATE = "INVALID_DATE";
export const INVALID_RESET_PASSWORD_TOKEN = "INVALID_RESET_PASSWORD_TOKEN";
export const LESS_THEN_NOW = "LESS_THEN_NOW";
export const MORE_THEN_NOW = "MORE_THEN_NOW";
export const AFTER_OR_SAME_AS_SPECIFIC_DATE = "AFTER_OR_SAME_AS_SPECIFIC_DATE";
export const BEFORE_OR_SAME_AS_SPECIFIC_DATE =
  "BEFORE_OR_SAME_AS_SPECIFIC_DATE";
export const SEARCH_LENGTH_MIN = "SEARCH_LENGTH_MIN";
export const CUSTOMER_IS_NOT_VOLT_MEMBER = "CUSTOMER_IS_NOT_VOLT_MEMBER";
export const INVALID_PROTOCOL_IN_URL_MESSAGE =
  "Value must be a valid URL with https protocol";
export const INVALID_URL_MESSAGE = "Value must be a valid URL";
export const INVALID_SORT_CODE_VALUE = "INVALID_SORT_CODE_VALUE";
export const INVALID_SWIFT_VALUE = "INVALID_SWIFT_VALUE";
export const ALPHANUMERIC_ONLY = "ALPHANUMERIC_ONLY";
export const NUMBERS_ONLY = "NUMBERS_ONLY";
export const NAMED_FIELD_LENGTH_MAX = "NAMED_FIELD_LENGTH_MAX";
export const ENTER_CUSTOMER_NAME = "ENTER_CUSTOMER_NAME";
export const NO_CUSTOMERS_FOUND = "NO_CUSTOMERS_FOUND";
export const MORE_THAN_ZERO = "MORE_THAN_ZERO";
export const INVALID_REGEX = "INVALID_REGEX";
export const WRONG_VALUE_FOR_CURRENT_PROVIDER =
  "WRONG_VALUE_FOR_CURRENT_PROVIDER";
