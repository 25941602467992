import "api";
import "i18n";
import "react-toastify/dist/ReactToastify.min.css";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import * as Sentry from "@sentry/react";
import { ConnectedRouter } from "connected-react-router";
import { initializeFeatureFlags } from "feature-flags";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { LOCAL_STORAGE_KEYS } from "constants/LocalStorageItems";
import { sentryConfig } from "sentry";
import { getConfiguredStore, history, persistor } from "store";
import { LocalStorage } from "utils/LocalStorage";

import { App } from "./App";

import type { AppWindow } from "appWindow";

declare let window: AppWindow;

let ProfiledApp: React.FC = App;

const environment = import.meta.env.FUZEBOX_ENV || "local";

if (environment !== "local" && import.meta.env.FUZEBOX_SENTRY_URL) {
  Sentry.init(sentryConfig(environment, history));

  ProfiledApp = Sentry.withProfiler(App);
}

const store = getConfiguredStore();

// expose store to dev, ex: Cypress
if (environment !== "prod") {
  window.store = store;
}

initializeFeatureFlags();

if (LocalStorage.getItem(LOCAL_STORAGE_KEYS.useMSWMockResponses)) {
  import("./__mocks__/browser").then(({ worker }) => {
    worker.start({
      onUnhandledRequest: "bypass",
    });

    worker.listHandlers();
  });
}

const container = document.getElementById("root");

if (!container) {
  throw new Error("Not found root element");
}

const root = createRoot(container);

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor(store)}>
      <ConnectedRouter history={history}>
        <LocalizationProvider dateAdapter={AdapterLuxon} locale="en">
          <ProfiledApp />
        </LocalizationProvider>
      </ConnectedRouter>
    </PersistGate>
  </Provider>
);
