import { QUICK_FILTERS_KEY } from "modules/common/constants/QuickFilters";

export const FILTERS_KEYS = {
  QUICK_FILTERS: QUICK_FILTERS_KEY,
} as const;

export const QUICK_FILTERS = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  VOLT_ADMIN: "VOLT_ADMIN",
} as const;

export const QUICK_FILTERS_VALUES = Object.values(QUICK_FILTERS);

export const DEFAULT_FILTERS = {
  quickFilters: [],
};

export const DEFAULT_FILTERS_CONFIGURATION = {
  quickFilters: {
    array: true,
    defaultValue: [],
  },
};
