import { GlobalStyles as MuiGlobalStyles, styled } from "@mui/material";
import { useRef } from "react";

import { PAGE_HEIGHT } from "constants/styles";
import { HelpHeaderProvider } from "context/HelpHeaderContext";
import { useApplicationShell } from "hooks/useApplicationShell";
import { Header } from "modules/common/components/Header";
import { Sidebar } from "modules/common/components/Sidebar";
import { SIDEBAR_WIDTH } from "modules/common/constants/Sidebar";
import { BreadcrumbsProvider } from "modules/common/context/BreadcrumbsContext";
import { shouldForwardPropHelper } from "utils/shouldForwardPropHelper";

import type { ReactNode } from "react";

export interface DashboardProps {
  children?: ReactNode;
}

export function DashboardLayout({ children }: DashboardProps) {
  const rootRef = useRef<HTMLDivElement>(null);
  const {
    toggleSidebar,
    isSidebarOpen,
    handleTransitionEnd,
    isSidebarAnimationInProgress,
  } = useApplicationShell();

  return (
    <NewRoot>
      <HelpHeaderProvider>
        <BreadcrumbsProvider>
          <GlobalStyle />
          <AppContainer
            isOpen={isSidebarOpen}
            ref={rootRef}
            onTransitionEnd={handleTransitionEnd}
          >
            <Sidebar
              isAnimationInProgress={isSidebarAnimationInProgress}
              isOpen={isSidebarOpen}
            />
            <MainWrapper>
              <Header onMenuClick={toggleSidebar} />
              <Layout>
                <ContentWrapper>{children}</ContentWrapper>
              </Layout>
            </MainWrapper>
          </AppContainer>
        </BreadcrumbsProvider>
      </HelpHeaderProvider>
    </NewRoot>
  );
}

const Layout = styled("div")({
  display: "flex",
  flex: 1,
  flexDirection: "column",
});

const GlobalStyle = () => (
  <MuiGlobalStyles
    styles={{
      "html,body,#root": {
        height: "100%",
      },

      "*, ::after, ::before": {
        boxSizing: "inherit",
      },
    }}
  />
);

const NewRoot = styled("div")({
  minHeight: "100vh",
  boxSizing: "border-box",
});

interface AppContainerProps {
  isOpen: boolean;
}

const APP_CONTAINER_PROPS = new Set(["isOpen"]);

const AppContainer = styled("div", {
  shouldForwardProp: (prop) =>
    shouldForwardPropHelper(prop, APP_CONTAINER_PROPS),
})<AppContainerProps>(({ isOpen }) => ({
  display: "grid",
  gridTemplateColumns: "0px 100%",
  // On Safari versions < 17 grid-template-columns transition is not triggering transitionend event
  // As a workaround we are animating top which should have no effect on static element
  transition: "grid-template-columns 0.3s, top 0.3s",

  top: 0,
  ...(isOpen && {
    top: 1,
    gridTemplateColumns: `${SIDEBAR_WIDTH}px calc(100% - ${SIDEBAR_WIDTH}px)`,
  }),
}));

const ContentWrapper = styled("div")(({ theme }) => ({
  height: PAGE_HEIGHT,
  backgroundColor: theme.palette.ui10,
  overflow: "auto",
}));

const MainWrapper = styled("div")({
  minWidth: "450px",
});
