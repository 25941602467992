import { COMMON_TRANSLATION_KEYS } from "constants/commonTranslationsKeys";
import { LOCAL_STORAGE_KEYS } from "constants/LocalStorageItems";
import { ACTION_DETAILS, ACTION_LIST } from "models/role/action";
import { SUBJECT_CASH_MANAGEMENT_VOLT_ACCOUNTS } from "models/role/subject";
import { lazyRetryWithPreload } from "utils/lazyRetryWithPreload";

import { PAGE_URLS } from "../constants/PageUrls";

import type { Route } from "routes/RouteModel";

const List = lazyRetryWithPreload(() => import("../pages/List"), "List");

const Details = lazyRetryWithPreload(
  () => import("../pages/Details"),
  "Details"
);

const areVoltAccountsEnabled = localStorage.getItem(
  LOCAL_STORAGE_KEYS.voltAccounts
);

export const voltAccountsRoutes: Route[] = areVoltAccountsEnabled
  ? [
      {
        title: [COMMON_TRANSLATION_KEYS.accountsSidebarTitle],
        path: PAGE_URLS.list,
        showChildrenInSidebar: false,
        component: List,
        can: {
          do: ACTION_LIST,
          on: SUBJECT_CASH_MANAGEMENT_VOLT_ACCOUNTS,
        },
      },
      {
        path: PAGE_URLS.details,
        component: Details,
        showChildrenInSidebar: false,
        can: {
          do: ACTION_DETAILS,
          on: SUBJECT_CASH_MANAGEMENT_VOLT_ACCOUNTS,
        },
      },
    ]
  : [];
