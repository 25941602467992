import { styled } from "@mui/material";
import { DataGridPlaceholder } from "@volt_developers/components";
import { Link } from "react-router-dom";

import { TEST_IDS } from "../constants/TestIds";

import type { SxProps, Theme } from "@mui/material";
import type { ReactNode } from "react";

import type { NonUndefined } from "utils/types";

interface PageErrorProps {
  buttonLabel: ReactNode;
  description: NonUndefined<ReactNode>;
  goBackLink: string;
  title: NonUndefined<ReactNode>;
  sx?: SxProps<Theme>;
}

export function PageError({
  title,
  description,
  goBackLink,
  buttonLabel,
  sx,
}: PageErrorProps) {
  return (
    <MainWrapper sx={sx}>
      <InnerWrapper>
        <DataGridPlaceholder.Header data-testid={TEST_IDS.notFoundHeader}>
          {title}
        </DataGridPlaceholder.Header>
        <DataGridPlaceholder.Description
          data-testid={TEST_IDS.notFoundDescription}
        >
          {description}
        </DataGridPlaceholder.Description>
        <DataGridPlaceholder.Button
          component={Link}
          data-testid={TEST_IDS.notFoundButton}
          to={goBackLink}
        >
          {buttonLabel}
        </DataGridPlaceholder.Button>
      </InnerWrapper>
    </MainWrapper>
  );
}

const InnerWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  maxWidth: 377,
  color: theme.palette.text.high,
}));

const MainWrapper = styled("div")(({ theme }) => ({
  gridColumnStart: 1,
  gridColumnEnd: -1,
  width: "100%",
  minHeight: "330px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(4),
}));
