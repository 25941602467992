import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { ORDER_ASC } from "api/helpers/pagination";
import { portalApi, TAGS } from "api/rtkApi";
import { useGetCustomersQuery } from "modules/customers/api/Customers";
import { useGetCustomer } from "modules/customers/hooks/useGetCustomer";
import { useFavourites } from "modules/user-settings/hooks/useFavourites";
import { setGlobalFilteredCustomer } from "store/appSlice";
import { findChildrenIds } from "utils";

import type { MouseEvent } from "react";

import type {
  GetCustomerHierarchyResponse,
  GetCustomerResponse,
} from "modules/customers/api/Customers";
import type { Customer } from "modules/customers/models/Customer";
import type { UUID } from "utils";

export function useGlobalFilterSearch() {
  const [search, setSearch] = useState(INITIAL_SEARCH);
  const [page, setPage] = useState(INITIAL_PAGE);
  const [searchResults, setSearchResults] = useState<SearchResults>([]);

  const [persistedSelectedCustomer, setPersistedSelectedCustomer] =
    useState<PersistedSelectedCustomerType | null>(null);
  const dispatch = useDispatch();
  const { addToFavourites, removeFromFavourites } = useFavourites();

  const { customer, isCustomerLoading, isCustomerFetching } = useGetCustomer({
    id: persistedSelectedCustomer?.id ?? "",
    options: {
      skip: !persistedSelectedCustomer,
    },
  });

  const {
    currentData: customersData,
    isLoading: isCustomersDataLoading,
    isFetching: isCustomersDataFetching,
  } = useGetCustomersQuery({
    ...GET_CUSTOMERS_PARAMS,
    page,
    ...(search.length ? { search } : {}),
  });

  const isFetching = isCustomersDataFetching;
  const isLoading = isCustomersDataLoading;

  const dataToLoad = customersData?.customers;
  const hasMore = dataToLoad?.length === RESPONSE_DATA_LIMIT;

  useEffect(() => {
    const canBeLoaded = Array.isArray(dataToLoad) && Boolean(dataToLoad.length);

    if (canBeLoaded) {
      setSearchResults((prevResults) => {
        if (
          page === INITIAL_PAGE &&
          search === INITIAL_SEARCH &&
          prevResults.length
        ) {
          return dataToLoad;
        }

        return prevResults.concat(dataToLoad);
      });
    }
  }, [dataToLoad, page, search]);

  useEffect(() => {
    if (
      page === INITIAL_PAGE &&
      search === INITIAL_SEARCH &&
      !searchResults.length &&
      dataToLoad?.length
    ) {
      return setSearchResults(dataToLoad);
    }
  }, [dataToLoad, page, search, searchResults]);

  const handleFavouriteClick =
    (isFavourite: boolean, id: string) =>
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();

      if (isFavourite) {
        removeFromFavourites(id);
      } else {
        addToFavourites(id);
      }

      setSearchResults((prevResults) =>
        prevResults.map((result) =>
          result.id === id ? { ...result, isFavourite: !isFavourite } : result
        )
      );
    };

  const onSearch = (search: string) => {
    setSearchResults([]);
    setSearch(search);
    setPage(INITIAL_PAGE);
  };

  const reset = () => {
    setSearch(INITIAL_SEARCH);
    setSearchResults([]);
    setPage(INITIAL_PAGE);
    dispatch(portalApi.util.invalidateTags([TAGS.CUSTOMERS]));
  };

  const onSelectCustomer = useCallback(
    ({ id, name, children, globalFilterIds }: SelectedCustomer) => {
      let allIds: UUID[] = [id];

      if (!children && customer?.id !== id) {
        setPersistedSelectedCustomer({
          id,
          name,
          allIds,
        });

        return;
      }

      if (Array.isArray(globalFilterIds) && globalFilterIds.length > 0) {
        allIds = globalFilterIds;
      } else if (children || customer?.children) {
        // to be removed with https://supercharged.atlassian.net/browse/MP-2292
        const childrenData = children || customer?.children || [];

        allIds = allIds.concat(findChildrenIds(childrenData));
      }

      setPage(INITIAL_PAGE);
      setSearch(INITIAL_SEARCH);
      dispatch(
        setGlobalFilteredCustomer({
          id,
          name,
          allIds,
        })
      );
    },
    [customer?.children, customer?.id, dispatch]
  );

  useEffect(() => {
    if (customer) {
      const { id, name, children, globalFilterIds } = customer;

      onSelectCustomer({ id, name, children, globalFilterIds });
      setPersistedSelectedCustomer(null);
    }
  }, [customer, onSelectCustomer]);

  const handleLoadMore = () => {
    if (hasMore && !isLoading && !isFetching) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const isNoResults =
    !isFetching &&
    !isLoading &&
    !Boolean(dataToLoad?.length) &&
    !Boolean(searchResults.length);

  return {
    searchResults,
    isLoading,
    isCustomerLoading: isCustomerLoading || isCustomerFetching,
    hasMore,
    isNoResults,
    onSearch,
    onSelectCustomer,
    handleLoadMore,
    handleFavouriteClick,
    reset,
  };
}

interface PersistedSelectedCustomerType extends SelectedCustomer {
  allIds: UUID[];
}

export type SearchResults = SearchResultCustomer[];

export interface SearchResultCustomer {
  descendantsCount: Customer["descendantsCount"];
  id: GetCustomerHierarchyResponse["id"];
  isFavourite: Customer["isFavourite"];
  name: GetCustomerHierarchyResponse["name"];
}

export interface SelectedCustomer {
  id: GetCustomerHierarchyResponse["id"];
  name: GetCustomerHierarchyResponse["name"];
  children?: GetCustomerResponse[];
  globalFilterIds?: GetCustomerResponse["globalFilterIds"];
  parentId?: GetCustomerHierarchyResponse["parentId"];
}

const INITIAL_SEARCH = "";
const INITIAL_PAGE = 1;
const RESPONSE_DATA_LIMIT = 100;
const GET_CUSTOMERS_PARAMS = {
  limit: RESPONSE_DATA_LIMIT,
  favouriteOrder: ORDER_ASC,
  order: ORDER_ASC,
  sortBy: "name",
} as const;
