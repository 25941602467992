import { CircularProgress, styled } from "@mui/material";

import { PAGE_HEIGHT } from "constants/styles";

export function Spinner() {
  return (
    <Wrapper>
      <CircularProgress />
    </Wrapper>
  );
}

const Wrapper = styled("div")({
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  minHeight: PAGE_HEIGHT,
});
