import { getRouteWithPersistedFilterParams } from "modules/common/utils/getRouteWithFilterParams";

import {
  DEFAULT_FILTERS_CONFIGURATION,
  FILTERS_PERSISTED_KEY,
} from "./Filters";

import type { UUID } from "utils";

const BASE_URL = "/configuration/accounts";

export const PAGE_URLS = {
  list: BASE_URL,
  getList: () =>
    getRouteWithPersistedFilterParams(
      PAGE_URLS.list,
      FILTERS_PERSISTED_KEY,
      DEFAULT_FILTERS_CONFIGURATION
    ),
  create: `${BASE_URL}/create`,
  edit: `${BASE_URL}/:id/edit`,
  getLinkToDetails: (id: UUID) => `${BASE_URL}/${id}`,
  getLinkToSettlementSettings: (id: UUID) =>
    `${BASE_URL}/${id}/settlement-settings`,
  getLinkToEdit: (id: UUID) => `${BASE_URL}/${id}/edit`,
  accountDetails: `${BASE_URL}/:id`,
  accountSettlementSettings: `${BASE_URL}/:id/settlement-settings`,
};
