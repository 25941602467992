import { GlobalStyles as MuiGlobalStyles, styled } from "@mui/material";
import { getColor } from "@volt_developers/foundations";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import { AnimatedBackground } from "./AnimatedBackground";
import { COLORS } from "./colors";
import { Header, HEADER_HEIGHT } from "./Header";

import type { ReactNode } from "react";

export interface BasicLayoutProps {
  children?: ReactNode;
}

export function BasicLayout({ children }: BasicLayoutProps) {
  return (
    <Root>
      <GlobalStyle />
      <GoogleReCaptchaProvider
        reCaptchaKey={import.meta.env.FUZEBOX_RECAPTCHA_KEY ?? ""}
      >
        <Header />
        <AnimatedBackground />
        <ContentWrapper>{children}</ContentWrapper>
      </GoogleReCaptchaProvider>
    </Root>
  );
}

const GlobalStyle = () => (
  <MuiGlobalStyles
    styles={{
      "html,body,#root": {
        height: "100%",
      },

      "*, ::after, ::before": {
        boxSizing: "inherit",
      },

      body: {
        backgroundColor: getColor("blue10"),
      },
    }}
  />
);

const Root = styled("div")(({ theme }) => ({
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",

  background: COLORS.background,

  [theme.breakpoints.down("md")]: {
    gap: "24px",
  },
}));

const ContentWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  width: "100%",
  maxWidth: "520px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexGrow: 1,
  margin: "0 auto",
  marginBottom: HEADER_HEIGHT["regular"],

  "@media (max-height: 675px)": {
    margin: "24px auto",
    flexGrow: 0,
  },

  [theme.breakpoints.down("md")]: {
    margin: "0 auto",
    flexGrow: 0,
  },
}));
