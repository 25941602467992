import { COMMON_TRANSLATION_KEYS } from "constants/commonTranslationsKeys";
import { ACTION_DETAILS, ACTION_UPDATE } from "models/role/action";
import {
  SUBJECT_CASH_MANAGEMENT,
  SUBJECT_CASH_MANAGEMENT_PROVIDER_SETUP,
} from "models/role/subject";
import { lazyRetryWithPreload } from "utils/lazyRetryWithPreload";

import { lazyRetry } from "../../../utils/lazyRetry";
import { PAGE_URLS } from "../constants/PageUrls";
import { accountsRoutes } from "./accountList";

import type { Route } from "routes/RouteModel";

const Core = lazyRetryWithPreload(() => import("../pages/Core"), "Connect");

const Accounts = lazyRetryWithPreload(
  () => import("../pages/accounts/List"),
  "Accounts"
);

const Providers = lazyRetryWithPreload(
  () => import("../pages/Providers"),
  "Providers"
);

const Notifications = lazyRetryWithPreload(
  () => import("../pages/Notifications"),
  "Notifications"
);

const ISXFinancialConfiguration = lazyRetry(
  () => import("../pages/IsxFinancialConfiguration"),
  "ISXFinancialConfiguration"
);

export const connectRoutes: Route = {
  // title for this path is defined in children routes
  path: PAGE_URLS.core,
  component: Core,
  hasNestedSwitch: true,
  showChildrenInSidebar: false,
  can: {
    do: ACTION_DETAILS,
    on: SUBJECT_CASH_MANAGEMENT,
  },
  children: [
    ...accountsRoutes,
    {
      title: "ISX payout configuration",
      path: PAGE_URLS.isxFinancialConfiguration,
      component: ISXFinancialConfiguration,
      can: {
        do: ACTION_UPDATE,
        on: SUBJECT_CASH_MANAGEMENT_PROVIDER_SETUP,
      },
    },
  ],
};

export const coreRoutes: Route[] = [
  {
    title: [
      COMMON_TRANSLATION_KEYS.connectAccounts,
      COMMON_TRANSLATION_KEYS.list,
    ],
    path: PAGE_URLS.core,
    component: Accounts,
    can: {
      do: ACTION_DETAILS,
      on: SUBJECT_CASH_MANAGEMENT,
    },
  },
  {
    title: [
      COMMON_TRANSLATION_KEYS.connectAccounts,
      COMMON_TRANSLATION_KEYS.providers,
    ],
    path: PAGE_URLS.providers,
    component: Providers,
    showChildrenInSidebar: false,
    can: {
      do: ACTION_DETAILS,
      on: SUBJECT_CASH_MANAGEMENT_PROVIDER_SETUP,
    },
  },
  {
    title: [
      COMMON_TRANSLATION_KEYS.connectAccounts,
      COMMON_TRANSLATION_KEYS.notifications,
    ],
    path: PAGE_URLS.notifications,
    component: Notifications,
    can: {
      do: ACTION_DETAILS,
      on: SUBJECT_CASH_MANAGEMENT,
    },
  },
];
