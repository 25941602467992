import {
  ACCOUNT_STATUS_ACTIVE,
  ACCOUNT_STATUS_INACTIVE,
} from "../constants/AccountStatus";

import type { CountryCode } from "models/CountryCode";
import type { SupportedCurrencyCode } from "models/CurrencyCode";
import type { UUID } from "utils";

import type { AccountFilters } from "../models/Filters";

export type FilterParams = {
  active?: boolean;
  currency?: SupportedCurrencyCode[];
  country?: CountryCode[];
  customer?: UUID[];
};

export function prepareAccountFilters(filters: Partial<AccountFilters> = {}) {
  const params: FilterParams = {};

  const hasActiveFilter = filters?.quickFilters?.includes(
    ACCOUNT_STATUS_ACTIVE
  );
  const hasInactiveFilter = filters?.quickFilters?.includes(
    ACCOUNT_STATUS_INACTIVE
  );

  if (hasActiveFilter && !hasInactiveFilter) {
    params["active"] = true;
  }

  if (hasInactiveFilter && !hasActiveFilter) {
    params["active"] = false;
  }

  if (filters.currency) {
    params["currency"] = filters.currency;
  }

  if (filters.country) {
    params["country"] = filters.country;
  }

  if (filters.customers) {
    params["customer"] = filters.customers;
  }

  return params;
}
