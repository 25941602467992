import type { UUID } from "utils";

export const URL_PARAMS = {
  PREVIEW_ACCOUNT: "preview",
  PREVIEW_TRANSACTION: "transaction",
};

const BASE_URL = "/accounts";

export const PAGE_URLS = {
  list: BASE_URL,
  connect: `${BASE_URL}/connect`,
  details: `${BASE_URL}/:id`,
  getLinkToAccountDetails: (id: UUID) => `${BASE_URL}/${id}`,
  getLinkToAccountPreview: (id: UUID, params?: string) => {
    if (params) {
      return `${BASE_URL}?${params}&${URL_PARAMS.PREVIEW_ACCOUNT}=${id}`;
    }

    return `${BASE_URL}?${URL_PARAMS.PREVIEW_ACCOUNT}=${id}`;
  },
  getLinkToTransactionPreview: (accountId: UUID, transactionId: UUID) =>
    `${BASE_URL}/${accountId}?${URL_PARAMS.PREVIEW_TRANSACTION}=${transactionId}`,
};
