import * as Yup from "yup";

import { httpsUrlPattern } from "utils/patterns";

declare module "yup" {
  export interface StringSchema {
    httpsUrl(): this;
  }
}

Yup.addMethod<Yup.StringSchema>(Yup.string, "httpsUrl", function () {
  return this.url().matches(httpsUrlPattern);
});
