import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { useCurrentUserAnalyticsProperties } from "modules/auth/hooks/useCurrentUserAnalyticsProperties";
import { useEvent } from "modules/common/hooks/useEvent";

import { useActive } from "../../hooks/useActive";

const DEFAULT_PAGE_TITLE = "Volt Fuzebox" as const;

export const useRouterAnalytics = () => {
  const userAnalyticsProperties = useCurrentUserAnalyticsProperties();
  const location = useLocation();
  const [currentPageTitle, setCurrentPageTitle] =
    useState<string>(DEFAULT_PAGE_TITLE);
  const { isActive: hasGTMLoaded, activate: setGTMLoaded } = useActive(
    (window.dataLayer?.length ?? -1) > 0
  );
  const lastReportedPageTitle = useRef("");

  const onHelmetChangeClintState = ({ title }: { title?: string }) => {
    if (title && title !== DEFAULT_PAGE_TITLE) {
      setCurrentPageTitle(title);
    }
  };

  useEvent(GTMLoadedEvent, setGTMLoaded);

  useEffect(() => {
    if (
      hasGTMLoaded &&
      currentPageTitle !== DEFAULT_PAGE_TITLE &&
      currentPageTitle !== lastReportedPageTitle.current
    ) {
      lastReportedPageTitle.current = currentPageTitle;
      window.dataLayer?.push({
        event: "virtualPageView",
        pageUrl: location.pathname,
        pageTitle: currentPageTitle,
        ...userAnalyticsProperties,
      });
    }
  }, [
    location,
    hasGTMLoaded,
    currentPageTitle,
    lastReportedPageTitle,
    userAnalyticsProperties,
  ]);

  return {
    onHelmetChangeClintState,
    defaultPageTitle: DEFAULT_PAGE_TITLE,
  };
};

export const GTMLoadedEvent = "GTMLoaded" as const;
