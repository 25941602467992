import { alpha, ClickAwayListener, styled } from "@mui/material";
import { SearchInput, SelectCustomer } from "@volt_developers/components";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { COMMON_TRANSLATION_KEYS } from "constants/commonTranslationsKeys";
import { DEBOUNCE_DEFAULT_TIME_IN_MS } from "modules/common/constants/Debounce";

import { SIDEBAR_WIDTH } from "../../../constants/Sidebar";
import { TEST_IDS } from "../../../constants/TestIds";
import { GlobalFilterSearchList } from "./GlobalFilterSearchList";
import { GlobalFilterSearchNoResults } from "./GlobalFilterSearchNoResults";

import type { Dispatch, MouseEvent, SetStateAction } from "react";

import type {
  SearchResultCustomer,
  SearchResults,
  SelectedCustomer,
} from "./useGlobalFilterSearch";

export interface GlobalFilterSearchProps {
  handleLoadMore: VoidFunction;
  hasMore: boolean;
  isLoading: boolean;
  isNoResults: boolean;
  onCloseFilterSearch: Dispatch<SetStateAction<boolean>>;
  onFavouriteClick: (
    isFavourite: boolean,
    id: string
  ) => (event: MouseEvent<HTMLDivElement>) => void;
  onSearch: (search: string) => void;
  onSelectCustomer: (customer: SelectedCustomer) => void;
  searchResults: SearchResults;
}

export function GlobalFilterSearch({
  onCloseFilterSearch,
  searchResults,
  isLoading,
  isNoResults,
  hasMore,
  onSearch,
  onSelectCustomer,
  handleLoadMore,
  onFavouriteClick,
}: GlobalFilterSearchProps) {
  const { t } = useTranslation("common");
  const handleCloseFilterSearch = useCallback(
    () => onCloseFilterSearch(false),
    [onCloseFilterSearch]
  );
  const searchRef = useRef<HTMLInputElement>(null);

  const handleSelectCustomer = (selectedCustomer: SearchResultCustomer) => {
    onSelectCustomer(selectedCustomer);
    handleCloseFilterSearch();
  };

  const handleSearchChange = debounce((search: string) => {
    onSearch(search);
  }, DEBOUNCE_DEFAULT_TIME_IN_MS);

  const handleClear = () => {
    onSearch("");

    if (searchRef.current) {
      searchRef.current.value = "";
    }
  };

  useEffect(() => {
    searchRef.current?.focus();
  }, []);

  return (
    <Overlay>
      <ClickAwayListener onClickAway={handleCloseFilterSearch}>
        <Container data-testid={TEST_IDS.globalFilterSearch}>
          <SelectCustomer.Header sx={{ p: 2 }}>
            <SearchInput
              data-testid={TEST_IDS.globalFilterSearchInput}
              placeholder={t(COMMON_TRANSLATION_KEYS.searchCustomers)}
              ref={searchRef}
              onChange={(event) => handleSearchChange(event.target.value)}
              onClear={handleClear}
            />
          </SelectCustomer.Header>
          {isNoResults ? (
            <GlobalFilterSearchNoResults
              search={searchRef.current?.value ?? ""}
              handleResetSearch={handleClear}
            />
          ) : (
            <GlobalFilterSearchList
              hasMore={hasMore}
              isLoading={isLoading}
              searchResults={searchResults}
              handleLoadMore={handleLoadMore}
              handleSelectCustomer={handleSelectCustomer}
              onFavouriteClick={onFavouriteClick}
            />
          )}
        </Container>
      </ClickAwayListener>
    </Overlay>
  );
}

const GLOBAL_FILTER_SEARCH_WIDTH = 315;

const Overlay = styled("div")({
  position: "absolute",
  left: SIDEBAR_WIDTH,
  backgroundColor: alpha("#333333", 0.33),
  height: "100%",
  width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
  overflow: "hidden",
});

const Container = styled("div")(({ theme }) => ({
  width: GLOBAL_FILTER_SEARCH_WIDTH,
  display: "flex",
  flexDirection: "column",
  backgroundColor: theme.palette.background.paper,
  height: "100%",
}));
