import { Link, styled, Typography } from "@mui/material";
import {
  Banner,
  Checkbox,
  Dialog,
  ExclamationMarkIcon,
} from "@volt_developers/components";
import { getPadding, getSpacing } from "@volt_developers/foundations";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { useOperationState } from "hooks/operationsState";
import { useCurrentUser } from "modules/auth/hooks/useCurrentUser";

import { TEST_IDS } from "../../constants/testIds";
import { TRANSLATION_KEYS } from "../../constants/TranslationKeys";
import {
  acceptLicenceAgreement,
  operationPath as operationPaths,
} from "../../store";
import { AgreeAndAccessButton } from "./AgreeAndAccessButton";
import { LicenceAgreementContent } from "./LicenceAgreementContent";
import { LogoutButton } from "./LogoutButton";

import type { ChangeEvent } from "react";

export function TermsAndConditionsDialog() {
  const { t } = useTranslation("userSettings");
  const dispatch = useDispatch();
  const { currentUser } = useCurrentUser();

  const [isLicenceRead, setIsLicenceRead] = useState(false);
  const [isLicenceAccepted, setIsLicenceAccepted] = useState(false);

  const operationPath = operationPaths.acceptLicenceAgreement;
  const { operationLoading: acceptLicenceAgreementLoading } =
    useOperationState(operationPath);

  const doesUserNeedToUpdateAcceptance = Boolean(
    currentUser?.metadata?.termsAcceptanceDate
  );

  const dialogTitle = doesUserNeedToUpdateAcceptance
    ? t(TRANSLATION_KEYS.actionRequiredWeHaveUpdated)
    : t(TRANSLATION_KEYS.evaluationLicenceAgreementForVolt);

  const onSubmit = () => {
    dispatch(acceptLicenceAgreement());
  };

  const onCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsLicenceAccepted(event.target.checked);
  };

  return (
    <Dialog.Wrapper
      data-testid={TEST_IDS.termsAndConditionsDialog}
      maxWidth="lg"
      sx={{ width: 800, m: "auto" }}
      open
    >
      <Dialog.Title
        data-testid={TEST_IDS.termsAndConditionsTitle}
        sx={{ p: getSpacing(10, 8, 7, 8) }}
      >
        {dialogTitle}
      </Dialog.Title>
      <Banner.Wrapper
        sx={{ p: getPadding(4), mx: getSpacing(10) }}
        variant="alert"
        isTransparent
      >
        <Banner.Content>
          <Banner.IconWrapper>
            <ExclamationMarkIcon />
          </Banner.IconWrapper>
          <Typography
            data-testid={TEST_IDS.termsAndConditionsSubtitle}
            variant="h6"
          >
            {t(TRANSLATION_KEYS.pleaseReadCarefullyBeforeAccessing)}
          </Typography>
        </Banner.Content>
      </Banner.Wrapper>
      <Typography
        data-testid={TEST_IDS.termsAndConditionsDescription}
        sx={{ px: getSpacing(10), pt: getSpacing(4) }}
        variant="body2"
      >
        {t(TRANSLATION_KEYS.toAccessTheVoltSandboxEnvironment)}
      </Typography>
      <LicenceAgreementContent onIsLicenceRead={() => setIsLicenceRead(true)} />
      <Dialog.Actions>
        <Wrapper>
          <CheckboxWrapper>
            <Checkbox
              data-testid={TEST_IDS.termsAndConditionsCheckbox}
              disabled={!isLicenceRead}
              sx={{ mr: getSpacing(2) }}
              onChange={onCheckboxChange}
            />
            <Typography variant="body2">
              <Trans
                t={t}
                components={{
                  a: (
                    <Link
                      component="a"
                      href={PRIVACY_STATEMENT_URL}
                      target="_blank"
                    />
                  ),
                }}
              >
                {TRANSLATION_KEYS.iHaveReadAndAgree}
              </Trans>
            </Typography>
          </CheckboxWrapper>
          <ButtonWrapper>
            <LogoutButton />
            <AgreeAndAccessButton
              isDisabled={!isLicenceAccepted}
              isLoading={acceptLicenceAgreementLoading}
              onSubmit={onSubmit}
            />
          </ButtonWrapper>
        </Wrapper>
      </Dialog.Actions>
    </Dialog.Wrapper>
  );
}

const PRIVACY_STATEMENT_URL = "https://www.volt.io/generalprivacy";

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
});

const CheckboxWrapper = styled("div")({});

const ButtonWrapper = styled("div")({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  gap: getSpacing(8),
  marginTop: getSpacing(6),
});
