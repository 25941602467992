import type { ObjectValuesAsType } from "utils/types";

export type TYPE_ENV_SANDBOX = "sandbox";
export type TYPE_ENV_PRODUCTION = "prod";
export type APPLICATION_ENV = TYPE_ENV_PRODUCTION | TYPE_ENV_SANDBOX;
export const ENV_SANDBOX: TYPE_ENV_SANDBOX = "sandbox";
export const ENV_PRODUCTION: TYPE_ENV_PRODUCTION = "prod";

export const SERVICES = {
  API: "api",
  CONNECT: "connect",
  NOTIFICATIONS: "notifications",
  WORKFLOWS: "workflows",
  USERS: "users",
} as const;

export const ENVIRONMENT = {
  PRODUCTION: "production",
  SANDBOX: "sandbox",
} as const;

export type API_SERVICE = ObjectValuesAsType<typeof SERVICES>;

export type ENV_SENSITIVE_SERVICES = Exclude<
  API_SERVICE,
  typeof SERVICES.USERS | typeof SERVICES.WORKFLOWS
>;

export type ENV_INSENSITIVE_SERVICES = Exclude<
  API_SERVICE,
  ENV_SENSITIVE_SERVICES
>;

export type EnvironmentName = ObjectValuesAsType<typeof ENVIRONMENT>;

export type Environment = { environment: EnvironmentName };
