import { MAX_FIELD_LENGTH } from "modules/applications/constants/form";
import { array, boolean, number, object, string } from "utils/yup";

import type { InferType } from "utils/yup";

export interface ExtraNotificationFields
  extends InferType<typeof EXTRA_NOTIFICATION_FIELDS_SCHEMA> {}

const EXTRA_NOTIFICATION_FIELDS_SCHEMA = object({
  paymentNotificationSettingsFields: array().of(string().required()).optional(),
  paymentReceivedNotificationSettingsFields: array()
    .of(string().required())
    .optional(),
});

export interface VerifyNotificationSettings
  extends InferType<typeof VERIFY_NOTIFICATION_SETTINGS_SCHEMA> {}

const NOTIFICATION_URL_SCHEMA = string()
  .max(MAX_FIELD_LENGTH)
  .required()
  .httpsUrl();

export const VERIFY_NOTIFICATION_SETTINGS_SCHEMA = object({
  active: boolean().optional(),
  createdAt: object({
    date: string().required(),
    timezone: string().required(),
    timezone_type: number().required(),
  }).required(),
  email: string().max(MAX_FIELD_LENGTH).email().nullable(),
  enabled: boolean().required(),
  extraNotificationFields: EXTRA_NOTIFICATION_FIELDS_SCHEMA.required(),
  ownerId: string().uuid().required(),
  secret: string().optional(),
  sources: string().oneOf(["verify"]).optional(),
  url: NOTIFICATION_URL_SCHEMA,
  paymentReceivalEnabled: boolean().optional(),
});

export interface FieldsFilter extends InferType<typeof FIELDS_FILTER_SCHEMA> {}

export const FIELDS_FILTER_SCHEMA = object({
  _default: array().of(string().required()).optional(),
  PAYMENT_RECEIVED: array().of(string().required()).optional(),
});

export interface NotificationSettings
  extends InferType<typeof NOTIFICATION_SETTINGS_SCHEMA> {}

export const NOTIFICATION_SETTINGS_SCHEMA = object({
  createdAt: object({
    date: string().required(),
    timezone: string().required(),
    timezone_type: number().required(),
  }).required(),
  email: string().max(MAX_FIELD_LENGTH).email().required(),
  enabled: boolean().required(),
  fieldsFilter: FIELDS_FILTER_SCHEMA.required(),
  ownerId: string().required(),
  secret: string().required(),
  url: NOTIFICATION_URL_SCHEMA,
});

export interface NotificationSettingsUpdate
  extends InferType<typeof NOTIFICATION_SETTINGS_UPDATE_SCHEMA> {}

export const NOTIFICATION_SETTINGS_UPDATE_SCHEMA =
  NOTIFICATION_SETTINGS_SCHEMA.pick(["email", "url"]).shape({
    fieldsFilter: FIELDS_FILTER_SCHEMA.optional(),
  });

export interface VerifyNotificationSettingsUpdate
  extends InferType<typeof VERIFY_NOTIFICATION_SETTINGS_UPDATE_SCHEMA> {}

export const VERIFY_NOTIFICATION_SETTINGS_UPDATE_SCHEMA = object({
  enabled: boolean().optional(),
  extraNotificationFields:
    EXTRA_NOTIFICATION_FIELDS_SCHEMA.optional().default(undefined),
  url: NOTIFICATION_URL_SCHEMA,
});

export const PROPERTY_EXTRA_FIELDS = "fieldsFilter";
