import {
  FIELD_REQUIRED,
  GENERIC_ERROR,
  INVALID_EMAIL_ADDRESS,
  INVALID_HEX_COLOR,
  INVALID_PROTOCOL_IN_URL_MESSAGE,
  INVALID_REGEX,
  INVALID_URL_MESSAGE,
  LENGTH_EQUAL,
  LENGTH_MAX,
  LENGTH_MIN,
} from "constants/errors";

import { hexColor, httpsUrlPattern } from "../patterns";

import type { LocaleObject } from "yup";

export const yupLocale: LocaleObject = {
  mixed: {
    default: GENERIC_ERROR,
    required: FIELD_REQUIRED,
  },
  string: {
    matches: ({ regex }) => {
      if (regex === httpsUrlPattern) {
        return INVALID_PROTOCOL_IN_URL_MESSAGE;
      }

      if (regex === hexColor) {
        return { key: INVALID_HEX_COLOR };
      }

      return INVALID_REGEX;
    },
    min: ({ min }: { min: number }) => ({
      key: LENGTH_MIN,
      values: { $minLength: min },
    }),
    max: ({ max }: { max: number }) => ({
      key: LENGTH_MAX,
      values: { $maxLength: max },
    }),
    url: INVALID_URL_MESSAGE,
    length: ({ length }: { length: number }) => ({
      key: LENGTH_EQUAL,
      values: { $length: length },
    }),
    email: INVALID_EMAIL_ADDRESS,
  },
};
