import { styled, Typography } from "@mui/material";
import { ClipboardCopy } from "@volt_developers/components";

import { ShowSecretButton } from "components/details/ShowSecretButton";
import { TEST_IDS } from "constants/TestIds";

import { useCardClipboardSecretText } from "./useCardClipboardSecretText";

const SECRET_PLACEHOLDER = "••••••••••••";

export interface CardClipboardSecretTextProps {
  text: string;
  "data-testid"?: string;
  isCopyButtonVisibleOnHover?: boolean;
  isCopyable?: boolean;
  isTruncated?: boolean;
  isVisible?: boolean;
  onToggleVisibility?: () => void;
  secretPlaceholder?: string;
}

export function CardClipboardSecretText({
  "data-testid": dataTestId,
  text,
  isVisible: isVisibleProp,
  isTruncated,
  isCopyable = true,
  isCopyButtonVisibleOnHover = false,
  secretPlaceholder = SECRET_PLACEHOLDER,
  onToggleVisibility,
}: CardClipboardSecretTextProps) {
  const { isVisible, toggleVisibility } = useCardClipboardSecretText({
    isVisible: isVisibleProp,
    onToggleVisibility,
  });

  return (
    <Wrapper
      $isCopyButtonVisibleOnHover={isCopyButtonVisibleOnHover}
      $isCopyable={isCopyable}
      data-testid={dataTestId}
    >
      {isVisible ? (
        <Typography noWrap={isTruncated} variant="small">
          {text}
        </Typography>
      ) : (
        <Typography
          sx={{
            fontSize: 25,
            letterSpacing: 2,
            lineHeight: "20px",
          }}
        >
          {secretPlaceholder}
        </Typography>
      )}
      <ButtonsWrapper $isReversed={isCopyButtonVisibleOnHover}>
        <StyledClipboardCopy textToCopy={text} isCopyIconVisibleByDefault>
          {null}
        </StyledClipboardCopy>
        <ShowSecretButton
          isSecretVisible={isVisible}
          data-testid={
            dataTestId ? TEST_IDS.getToggleButton(dataTestId) : undefined
          }
          onClick={toggleVisibility}
        />
      </ButtonsWrapper>
    </Wrapper>
  );
}

const StyledClipboardCopy = styled(ClipboardCopy)({});

interface ButtonWrapperProps {
  $isReversed: boolean;
}

const ButtonsWrapper = styled("div")<ButtonWrapperProps>(({ $isReversed }) => ({
  flexDirection: $isReversed ? "row-reverse" : "row",
  display: "flex",
}));

interface WrapperProps {
  $isCopyButtonVisibleOnHover: boolean;
  $isCopyable: boolean;
}

const Wrapper = styled("div")<WrapperProps>(
  ({ $isCopyButtonVisibleOnHover, $isCopyable }) => ({
    display: "flex",
    border: "5 px solid blue",
    alignItems: "center",
    ...(!$isCopyable && { userSelect: "none" }),

    ...($isCopyButtonVisibleOnHover && {
      [`& ${StyledClipboardCopy}`]: {
        visibility: "hidden",
      },
    }),

    ...($isCopyButtonVisibleOnHover && {
      [`&:hover ${StyledClipboardCopy}`]: {
        visibility: "visible",
      },
    }),
  })
);
