import { createAction, createSlice } from "@reduxjs/toolkit";

import type { RootState } from "store";
import type { UUID } from "utils";
import type { ObjectWithId } from "utils/ObjectWithId";

import type { CheckoutBranding } from "../models/CheckoutBranding";
import type { CustomerLogoType } from "../models/CustomerLogo";

export interface SliceState {
  customerPreferencesBranding: CheckoutBranding | null;
}

export const initialState: SliceState = {
  customerPreferencesBranding: null,
};

export const slice = createSlice({
  name: "checkoutBranding",
  initialState,
  reducers: {
    setCustomerPreferencesBranding: (state, action) => {
      state.customerPreferencesBranding = action.payload;
    },
  },
});

export const fetchCustomerPreferencesBranding = createAction<ObjectWithId>(
  "fetchCustomerPreferencesBranding"
);

export const updateCustomerPreferencesBranding = createAction<{
  data: CheckoutBranding;
  id: UUID;
}>("updateCustomerPreferencesBranding");

export const updateCustomerLogo = createAction<{
  file: File;
  id: UUID;
  logoType: CustomerLogoType;
}>("updateCustomerLogo");

export const deleteCustomerLogo = createAction<{
  id: UUID;
  logoType: CustomerLogoType;
}>("deleteCustomerLogo");

export const selectCustomerPreferencesBranding = (state: RootState) =>
  state.checkoutBranding.customerPreferencesBranding;

export const operationPaths = {
  brandingPreferencesFetch: [
    "checkoutBranding",
    "customer-preferences-branding",
    "fetch",
  ],
  brandingPreferencesUpdate: [
    "checkoutBranding",
    "customer-preferences-branding",
    "update",
  ],
  logoDelete: (logoType: CustomerLogoType) => [
    "checkoutBranding",
    "logo",
    logoType,
    "delete",
  ],
  logoUpdate: (logoType: CustomerLogoType) => [
    "checkoutBranding",
    "logo",
    logoType,
    "update",
  ],
} as const;

export const { setCustomerPreferencesBranding } = slice.actions;

export const checkoutBrandingReducer = slice.reducer;
