/*eslint perfectionist/sort-objects: "error"*/

export const enGb = {
  branding: "Branding",
  brandingIsNotActive: "Branding is not active",
  brandingIsNotActiveDetails:
    "Custom branding haven't been configured. Checkout uses default Volt branding.",
  checkoutBranding: "Checkout branding",
  checkoutBrandingInactiveMessage:
    "Branding is not active, checkout uses default Volt branding",
  colors: "Colors",
  companyLogo: "Company logo",
  companyLogoRecommendedRatio: "Recommended aspect ratio {{ratio}}",
  companyLogoRecommendedRatioOtherwise:
    "Recommended aspect ratio {{ratio}}, otherwise it might not display properly",
  companyLogoRequirements: "Please provide images, max. size: {{maxSizeMB}}MB",
  configure: "Configure",
  configureCustomBranding: "Configure custom branding",
  customerDetails: "Customer details",
  customerName: "Customer name",
  defaultColor: "{{color}} (default)",
  desktop: "Desktop",
  dragAndDropImage: "Drag and drop image, or click to select files",
  edit: "Edit",
  information: "Information",
  insideQR: "Inside QR code",
  linkColor: "Link color",
  linkTextColor: "Link text color",
  mobile: "Mobile",
  pleaseProvideValidImageFile: "Please provide valid image file",
  primaryColor: "Primary color",
  primaryTextColor: "Primary text color",
  removingLogoFailed: "Removing logo failed, please try again",
  save: "Save",
  secondaryColor: "Secondary color",
  secondaryTextColor: "Secondary text color",
  selectCustomer: "Select customer",
  sidebarColor: "Sidebar color",
  sidebarTextColor: "Sidebar text color",
  status: "Status",
  tagline: "Tagline",
  toViewCheckoutBrandingSelectCustomer:
    "To view Checkout branding preferences, please select a customer in the select customer menu in the upper left corner.",
  uploadingLogoFailed: "Uploading logo failed, please try again",
  useDefaultVoltBranding: "Use default Volt branding",
};
