import { COMMON_TRANSLATION_KEYS } from "constants/commonTranslationsKeys";
import { lazyRetry } from "utils/lazyRetry";
import { lazyRetryWithPreload } from "utils/lazyRetryWithPreload";

import { PAGE_URLS } from "../constants";

import type { Route } from "routes/RouteModel";

const List = lazyRetryWithPreload(() => import("../pages/List"), "List");

const PayoutPage = lazyRetry(() => import("../pages/payout"), "PayoutPage");

const Create = lazyRetry(() => import("../pages/Create"), "Create");

export const payoutsRoutes: Route = {
  title: [COMMON_TRANSLATION_KEYS.payouts, COMMON_TRANSLATION_KEYS.list],
  path: PAGE_URLS.list,
  component: List,
  children: [
    {
      title: [COMMON_TRANSLATION_KEYS.payouts, COMMON_TRANSLATION_KEYS.new],
      path: PAGE_URLS.create,
      component: Create,
    },
    {
      // title for this path is defined inside details routing
      path: PAGE_URLS.details,
      component: PayoutPage,
      hasNestedSwitch: true,
    },
  ],
};
