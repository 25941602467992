import { useCallback, useState } from "react";

export function useActive(defaultValue = false) {
  const [isActive, setIsActive] = useState(defaultValue);

  const activate = useCallback(() => setIsActive(true), []);
  const deactivate = useCallback(() => setIsActive(false), []);
  const toggleActive = useCallback(() => setIsActive((prev) => !prev), []);

  return { isActive, activate, deactivate, toggleActive };
}
