import { COMMON_TRANSLATION_KEYS } from "constants/commonTranslationsKeys";
import { ACTION_CREATE, ACTION_DETAILS, ACTION_LIST } from "models/role/action";
import { SUBJECT_REFUND } from "models/role/subject";
import { lazyRetryWithPreload } from "utils/lazyRetryWithPreload";

import { PAGE_URLS } from "../constants/PageUrls";

import type { Route } from "routes/RouteModel";

const List = lazyRetryWithPreload(() => import("../pages/List"), "List");

const Create = lazyRetryWithPreload(() => import("../pages/Create"), "Create");

const Details = lazyRetryWithPreload(
  () => import("../pages/Details"),
  "Details"
);

export const refundsRoutes: Route = {
  title: [COMMON_TRANSLATION_KEYS.refunds, COMMON_TRANSLATION_KEYS.list],
  path: PAGE_URLS.list,
  component: List,
  showChildrenInSidebar: false,
  can: {
    do: ACTION_LIST,
    on: SUBJECT_REFUND,
  },
  children: [
    {
      title: [COMMON_TRANSLATION_KEYS.refunds, COMMON_TRANSLATION_KEYS.new],
      path: PAGE_URLS.create,
      component: Create,
      can: { do: ACTION_CREATE, on: SUBJECT_REFUND },
    },
    {
      // title for this path is defined inside details routing
      path: PAGE_URLS.details,
      component: Details,
      can: { do: ACTION_DETAILS, on: SUBJECT_REFUND },
      hasNestedSwitch: true,
    },
  ],
};
