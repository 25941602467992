/*eslint perfectionist/sort-objects: "error"*/

export const enGb = {
  accountDetails: "Account Details",
  accountIdentification: "Account identification",
  accountIdentificationAndBeneficiaryInformation:
    "Account identification and beneficiary information",
  administration: "Administration",
  applied: "Applied",
  approval: "Approval",
  approve: "Approve",
  approved: "Approved",
  approvedBy: "Approved by",
  approvedByMe: "Approved by me",
  areYouSure: "Are you sure you want to approve this request?",
  areYouSureYouWantToApprove: "Are you sure you want to approve this request?",
  areYouSureYouWantToCancel:
    "Are you sure you want to cancel this request? This action is irreversible.",
  areYouSureYouWantToDecline:
    "Are you sure you want to decline this request? You can choose to permanently reject this request or ask for changes.",
  backToList: "Back to the Workflows list",
  backToWorkflow: "Back to the Workflow",
  bankAccountCountryAndCurrency: "Bank account country and currency",
  bankAccountCreation: "Bank account creation",
  bankAccountDeactivation: "Bank account deactivation",
  bankAccountDetailsLikeIbanAndSwiftCode:
    "Bank account details like IBAN and Swift code (BIC). Account number and sort code can be displayed for particular currencies",
  bankAccountReactivation: "Bank account reactivation",
  byUser: "by <name />",
  cancel: "Cancel",
  canceled: "Canceled",
  cancelRequest: "Cancel request",
  cannotLoadCustomerNames:
    "Cannot load customer names. Please try again later.",
  cannotLoadUsernames: "Cannot load user names. Please try again later.",
  cannotLoadWorkflowsList:
    "Cannot load workflows list. Please try again later.",
  comment: "Comment",
  continue: "Continue",
  customer: "Customer",
  date: "Date (UTC)",
  deactivatedUser: "Deactivated user",
  decline: "Decline",
  declineRequest: "Decline request",
  declineType: "Decline type",
  edit: "Edit",
  editRequest: "Edit request - {{request}}",
  editRequestButton: "Edit request",
  effectiveDate: "Effective date",
  effectiveImmediately: "Effective immediately",
  eventName: "Event name",
  forTheChangeToAppearInTheSystem:
    "For the change to appear in the system you have to wait for approval from another team member. You can schedule an effective date for this request.",
  givenWorkflowCouldNotBeFound: "Given workflow could not be found.",
  goToDashboard: "Go to Dashboard",
  history: "History",
  inNextStepsYouWill:
    "In the next steps you will have the opportunity to schedule your request",
  instant: "Instant",
  key: "Key",
  locationAndCurrency: "Location and currency",
  moreFiltersText: "+ {{count}} more",
  noWorkflows: "No Workflows",
  pendingApproval: "Pending approval",
  pleaseConfirmYourRequestUsingTheSelectedSCAMethod:
    "Please confirm your request using the selected SCA method.",
  pleaseScheduleRequestOrSelectImmediateEffectiveDate:
    "Please, schedule request or select immediate effective date.",
  reject: "Reject",
  rejected: "Rejected",
  requestApplied: "Request applied",
  requestApproved: "Request approved",
  requestCancelation: "Request cancelation",
  requestCancelled: "Request cancelled",
  requestCreated: "Request created",
  requestedBy: "Requested by",
  requestedByMe: "Requested by me",
  requestForChanges: "Request for changes",
  requestKeyHasBeenApproved: "Request {{humanReadableId}} has been approved.",
  requestKeyHasBeenCanceled: "Request {{humanReadableId}} has been canceled.",
  requestKeyHasBeenDeclined: "Request {{humanReadableId}} has been declined.",
  requestKeyHasBeenRejected: "Request {{humanReadableId}} has been rejected.",
  requestKeyHasBeenReturned: "Request {{humanReadableId}} has been returned.",
  requestKeyHasBeenSubmitted: "Request {{humanReadableId}} has been submitted.",
  requestRejected: "Request rejected",
  requestResubmitted: "Request resubmitted",
  requestReturned: "Request returned",
  reset: "Reset",
  returned: "Returned",
  scheduled: "Scheduled",
  scheduledFor: "Scheduled for:",
  showLess: "Show Less",
  status: "Status",
  submittingRequest: "Submitting request",
  switch: "Switch",
  switchTheEnvironment: "Switch the environment?",
  thereAreNoWorkflowsAvailable:
    "There are no Workflows available for you at this time. Please, come back later or contact support if you believe there is an issue.",
  theseChangesMayImpactCashFlow: "These changes may impact cash flow",
  thisRequestDoesNotContainAnyChanges:
    "This request does not contain any changes. Are you sure you want to continue? You have to wait for approval from another team member. You can schedule an effective date for this request.",
  thisRequestHasBeenCanceled: "This request has been canceled",
  thisRequestHaveBeenAppliedOn:
    "This request have been applied on {{date}} UTC",
  thisRequestWasScheduledOnDateAndWillNotBeApplied:
    "This request was scheduled on {{ date }} and will not be applied",
  thisRequestWillAppearInTheSystem:
    "This request will appear in the system once it is approved. You can cancel your request before this stage.",
  thisRequestWillBeAppliedOn: "This request will be applied on {{date}} UTC",
  uniqIdForApi: "Unique ID for API",
  workflow: "Workflow",
  workflowCanNotBeFound: "Workflow could not be found.",
  workflowDetails: "Workflow Details",
  workflowEdit: "Workflow Edit",
  workflowId: "Workflow ID",
  workflows: "Workflows",
  workflowsAreAvailableOnlyOnProduction:
    "Workflows are available only on Production environment. Would you like to switch the environment?",
  youCannotInterfere:
    "You cannot interfere with this {{humanReadableId}} request as it has already been processed by someone else.",
};
