import type { CountryCode } from "models/CountryCode";
import type { CheckoutBranding } from "modules/checkout/models/CheckoutBranding";
import type { UUID } from "utils";
import type { ObjectValuesAsType } from "utils/types";

import type { CUSTOMER_DISCRIMINATOR } from "../constants/Customer";
import type { CustomerAddress } from "./CustomerAddress";
import type { CustomerCredentials } from "./CustomerCredentials";
import type { CustomerPaymentPreferences } from "./CustomerPaymentPreferences";
import type { PaymentProcessing } from "./PaymentProcessing";
import type { ProductFlags } from "./ProductFlags";

export const INCORPORATION_DATE_FORMAT = "yyyy-MM-dd";

export type CustomerDiscriminator = ObjectValuesAsType<
  typeof CUSTOMER_DISCRIMINATOR
>;

export type CustomerNames = Record<UUID, string>;

export type ParentCustomer = Pick<Customer, "id" | "name">;

export type IntegratorCustomer = {
  id: UUID;
  name: string;
};

export type BillingParentCustomer = {
  id: UUID;
  name: string;
};

export interface Customer {
  active: boolean;
  address: CustomerAddress | null;
  aisPreferences: unknown;
  children: Customer[];
  circuitBreakerEnabled: boolean;
  companyRegistrationNumber: string | null;
  countryCode: CountryCode;
  createdAt: string;
  credentials: CustomerCredentials[];
  descendantsCount: number;
  discr: CustomerDiscriminator;
  documentNumber: string | null;
  id: UUID;
  incorporationDate: string | null;
  isFavourite: boolean;
  legalName: string | null;
  merchantCategoryCode: string | null;
  name: string;
  parent: UUID | null;
  parentCustomer: ParentCustomer | null;
  paymentPreferences: CustomerPaymentPreferences;
  productFlags: ProductFlags;
  settlementPreferences: unknown[];
  terminateAt: string | null;
  billingParent?: UUID | null;
  billingParentCustomer?: BillingParentCustomer;
  calculatedIntegratorCustomer?: IntegratorCustomer;
  globalFilterIds?: UUID[];
  integrator?: UUID | null;
  integratorCustomer?: IntegratorCustomer;
  parentAccounts?: UUID[];
  paymentProcessing?: PaymentProcessing;
  preferences?: CheckoutBranding;
}

export const PROPERTY_CREDENTIALS = "credentials";
export const PROPERTY_MERCHANT_CATEGORY_CODE = "merchantCategoryCode";
export const PROPERTY_ADDRESS = "address";
export const PROPERTY_ACTIVE = "active";
export const PROPERTY_PAYMENT_PREFERENCES = "paymentPreferences";
export const PROPERTY_TYPE = "discr";
export const PROPERTY_CIRCUIT_BREAKER_ENABLED = "circuitBreakerEnabled";
export const PROPERTY_TERMINATE_AT = "terminateAt";
export const PROPERTY_SANDBOX_VERIFY = "sandbox.verify";
export const PROPERTY_PRODUCTION_VERIFY = "production.verify";
export const PROPERTY_SANDBOX_GATEWAY_AUSTRALIA = "sandbox.gatewayAustralia";
export const PROPERTY_PRODUCTION_GATEWAY_AUSTRALIA =
  "production.gatewayAustralia";
export const PROPERTY_SANDBOX_GATEWAY_US = "sandbox.gatewayUs";
export const PROPERTY_PRODUCTION_GATEWAY_US = "production.gatewayUs";
export const PROPERTY_SANDBOX_TRANSFORMER = "sandbox.transformer";
export const PROPERTY_PRODUCTION_TRANSFORMER = "production.transformer";
export const PROPERTY_SANDBOX_REFUND = "sandbox.connectRefund";
export const PROPERTY_PRODUCTION_REFUND = "production.connectRefund";
export const PROPERTY_SANDBOX_PAY_BY_LINK = "sandbox.payByLink";
export const PROPERTY_PRODUCTION_PAY_BY_LINK = "production.payByLink";
export const PROPERTY_SANDBOX_PAYOUTS = "sandbox.connectPayouts";
export const PROPERTY_PRODUCTION_PAYOUTS = "production.connectPayouts";
export const PROPERTY_SANDBOX_OPEN_LOOP_PAYOUTS =
  "sandbox.connect_open_loop_payouts";
export const PROPERTY_PRODUCTION_OPEN_LOOP_PAYOUTS =
  "production.connect_open_loop_payouts";
export const PROPERTY_SANDBOX_GATEWAY = "sandbox.gateway";
export const PROPERTY_PRODUCTION_GATEWAY = "production.gateway";

export const PROPERTY_SANDBOX_CONNECT = "sandbox.connect";
export const PROPERTY_PRODUCTION_CONNECT = "production.connect";

export const PROPERTY_SANDBOX_MATCHMETER = "sandbox.matchmeter";
export const PROPERTY_PRODUCTION_MATCHMETER = "production.matchmeter";

export const PROPERTY_SANDBOX_SAME_DAY_ACH = "sandbox.sameDayAch";
export const PROPERTY_PRODUCTION_SAME_DAY_ACH = "production.sameDayAch";

export const PROPERTY_PRODUCTION_SIGNAL = "sandbox.signal";
export const PROPERTY_SANDBOX_SIGNAL = "production.signal";
