import { styled, Typography } from "@mui/material";
import { ShieldIcon } from "@volt_developers/components";
import { useTranslation } from "react-i18next";

import { COMMON_TRANSLATION_KEYS } from "constants/commonTranslationsKeys";
import { Z_INDEX } from "modules/common/utils/zIndex";

import { TEST_IDS } from "../../constants/TestIds";
import { Logo } from "./Logo";

export const HEADER_HEIGHT = {
  regular: "56px",
  smaller: "48px",
} as const;

export function Header() {
  const { t } = useTranslation("common");

  return (
    <HeaderWrapper>
      <Logo />
      <ContentWrapper>
        <Typography data-testid={TEST_IDS.regulationInfo} variant="small">
          {t(COMMON_TRANSLATION_KEYS.regulatedByTheFCA)}
        </Typography>
        <ShieldIcon sx={{ width: 20, height: 20 }} />
      </ContentWrapper>
    </HeaderWrapper>
  );
}

const ContentWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: theme.palette.text.low,
  gap: "9px",
}));

const HeaderWrapper = styled("div")(({ theme }) => ({
  boxSizing: "border-box",
  minWidth: "100%",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: theme.palette.background.paper,
  boxShadow: `0px 1px 1px rgba(112, 144, 176, 0.12),
    0px 4px 8px rgba(112, 144, 176, 0.05)`,
  height: HEADER_HEIGHT["smaller"],
  padding: theme.spacing(1.5, 2),
  zIndex: Z_INDEX.HEADER,

  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(1.5, 4),
  },

  [theme.breakpoints.up("md")]: {
    height: HEADER_HEIGHT["regular"],
    padding: `${theme.spacing(1.5)} calc((100vw - ${
      theme.breakpoints.values.md
    }px) / 2)`,
  },
}));
