import { styled, Typography } from "@mui/material";
import { Dialog } from "@volt_developers/components";
import { getColor, getSpacing } from "@volt_developers/foundations";
import { useEffect, useRef } from "react";

import { useOperationState } from "hooks/operationsState";

import { TEST_IDS } from "../../constants/testIds";
import { useTermsAndConditions } from "../../hooks/useTermsAndConditions";
import { operationPath as operationPaths } from "../../store";
import { LicenceAgreementContentSkeleton } from "./LicenceAgreementContentSkeleton";

import type { UIEvent } from "react";

interface LicenceAgreementContentProps {
  onIsLicenceRead: VoidFunction;
}

export function LicenceAgreementContent({
  onIsLicenceRead: onIsRead,
}: LicenceAgreementContentProps) {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const licenceRef = useRef<HTMLPreElement | null>(null);
  const { operationSuccess: licenceAgreementFileOperationSuccess } =
    useOperationState(operationPaths.licenceAgreementFile);
  const wrapperHeight = wrapperRef.current?.offsetHeight ?? 0;
  const licenceHeight = licenceRef.current?.offsetHeight ?? 0;
  const licenceCantBeScrolled = wrapperHeight > licenceHeight;
  const {
    licenceAgreement: { file },
  } = useTermsAndConditions();

  useEffect(() => {
    if (licenceAgreementFileOperationSuccess && licenceCantBeScrolled) {
      onIsRead();
    }
  }, [onIsRead, licenceAgreementFileOperationSuccess, licenceCantBeScrolled]);

  if (!file) {
    return <LicenceAgreementContentSkeleton />;
  }

  const handleScroll = ({
    currentTarget: { scrollHeight, scrollTop, clientHeight },
  }: UIEvent<HTMLDivElement>) => {
    const bottom =
      scrollHeight - scrollTop < clientHeight + READ_LICENCE_THRESHOLD_IN_PX;

    if (bottom) {
      onIsRead();
    }
  };

  const parsedContent = file.content
    .trim()
    .split("\n")
    .filter(Boolean)
    .join("\n\n");

  return (
    <Dialog.Content
      data-testid={TEST_IDS.termsAndConditionsContent}
      sx={{
        minHeight: 229,
        maxHeight: 229,
        py: getSpacing(5),
      }}
      onScroll={handleScroll}
    >
      <div
        ref={wrapperRef}
        style={{
          backgroundColor: getColor("grey10"),
        }}
      >
        <Content>
          <Typography>{file.title}</Typography>
          <pre
            ref={licenceRef}
            style={{
              whiteSpace: "pre-line",
              fontSize: 12,
            }}
          >
            {parsedContent}
          </pre>
        </Content>
      </div>
    </Dialog.Content>
  );
}

const READ_LICENCE_THRESHOLD_IN_PX = 10;

const Content = styled("div")({
  textAlign: "left",
  padding: getSpacing(4),
});
