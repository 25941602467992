import type { UUID } from "utils";

/*eslint perfectionist/sort-objects: "error"*/
export const TEST_IDS = {
  activeInfo: "active-info",
  activeInfoResetButton: "active-info-reset-button",
  bannerResetPasswordButton: "banner-reset-password-button",
  cannotDisableTwoFAInfo: "cannot-disable-two-fa-info",
  configurationBreadcrumbLink: "configuration-breadcrumb-link",
  customerAutocomplete: "user-customer",
  customerCard: "user-customer-card",
  customerError: "user-customer-error",
  customerInput: "user-customer-input",
  customerName: "user-customer-name",
  editButton: "edit-button",
  email: "email",
  filtersPrefix: "users-filters",
  getUserRow: (id: UUID) => `user-id-${id}`,
  id: "id",
  name: "name",
  notFoundBreadcrumbLink: "user-not-found-breadcrumb-link",
  resetButton: "reset-button",
  scaButton: "sca-button",
  scaInfo: "sca-info",
  scaRemovalFailureToast: "sca-removal-failure-toast-id",
  scaRemovalSuccessToast: "sca-removal-success-toast-id",
  scaSection: "sca-section",
  twoFAButton: "2fa-button",
  twoFAInfo: "2fa-info",
  userAddButton: "user-add-button",
  userCreateFailureToast: "user-create-toast-id",
  userCreateSuccessToast: "user-create-toast-id",
  userDetailsBreadcrumbLink: "user-details-breadcrumb-link",
  userEditFailureToast: "user-edit-toast-id",
  userEditSuccessToast: "user-edit-toast-id",
  userEmail: "user-email",
  userName: "user-name",
  userPermissionsCardWrapper: "user-permissions-section",
  usersBreadcrumbLink: "users-breadcrumb-link",
  userToggle: "switch-label",
  userVoltEmployeeCheckbox: "user-volt-employee-checkbox",
  userVoltEmployeeControlTile: "user-volt-employee-control-tile",
} as const;
