import { subject } from "@casl/ability";
import axios from "axios";
import isNil from "lodash/isNil";

import { getErrorDetails } from "api/helpers/getErrorDetails";
import {
  addPaginationAndSort,
  getPaginationInfoFromHeaders,
  ORDER_DESC,
} from "api/helpers/pagination";
import { paramsSerializer } from "api/helpers/paramsSerializer";
import { portalApi, TAGS } from "api/rtkApi";
import { DEFAULT_PAGINATION } from "constants/defaultPagination";
import { SUBJECT_USER } from "models/role/subject";
import { pessimisticUpdate } from "utils/pessimisticUpdate";
import { preparePaginationParams } from "utils/preparePaginationParams";

import { prepareFilterParams } from "../utils/prepareFilterParams";

import type { AxiosRequestConfig } from "axios";

import type { PaginatedList } from "api/helpers/pagination";
import type { RTKMeta } from "models/RTKMeta";
import type { UUID } from "utils";
import type { ObjectWithId } from "utils/ObjectWithId";
import type { PaginationParams } from "utils/preparePaginationParams";

import type { OrderableColumns } from "../constants/User";
import type { UserFilters } from "../models/Filters";
import type { BasicUser, User, UserNames, UserPatch } from "../models/User";
import type { UserForm } from "../models/UserForm";

export async function getUsers(
  qsParams: { customerId?: UUID },
  options: AxiosRequestConfig
): Promise<PaginatedList<User>> {
  const { customerId } = qsParams;
  const params: Record<string, any> = {
    ...addPaginationAndSort<User>({
      orderBy: "createdAt",
      order: ORDER_DESC,
      limit: DEFAULT_PAGINATION.limit,
      page: 0,
    }),
  };

  if (!isNil(customerId)) {
    params["customer"] = customerId;
  }

  try {
    const response = await axios({
      ...options,
      method: "GET",
      url: "/users",
      params,
      paramsSerializer,
    });

    const { data = {}, headers: responseHeaders = {} } = response;

    const { totalItems, currentPage, currentLimit } =
      getPaginationInfoFromHeaders(responseHeaders);

    return {
      list: (data ?? []).map((user: User) => subject(SUBJECT_USER, user)),
      totalItems,
      currentPage,
      currentLimit,
    };
  } catch (error) {
    throw getErrorDetails(error);
  }
}

export async function postUser(user: UserForm, options: any): Promise<UUID> {
  try {
    const { data }: { data: User } = await axios({
      ...options,
      url: "/users",
      method: "POST",
      data: user,
    });

    return data.id;
  } catch (error) {
    throw getErrorDetails(error);
  }
}

export async function patchUser(
  id: UUID,
  user: Partial<UserPatch>,
  options: any
): Promise<UUID> {
  try {
    const { data }: { data: User } = await axios({
      ...options,
      method: "PUT",
      url: `/users/${id}`,
      data: user,
    });

    return data.id;
  } catch (error) {
    throw getErrorDetails(error);
  }
}

interface SendProductInterestData {
  productName: string;
}

export async function sendProductInterest(
  data: SendProductInterestData,
  options: AxiosRequestConfig
) {
  try {
    await axios({
      ...options,
      method: "POST",
      url: "/users/settings/product-interest",
      data,
    });
  } catch (error) {
    throw getErrorDetails(error);
  }
}

type GetUsersResponse = {
  totalItems: number;
  users: User[];
};

type GetUsersRequest = {
  customerHierarchyId?: UUID;
  filters?: Partial<UserFilters>;
} & PaginationParams<OrderableColumns>;

type GetUsernamesRequest = {
  userIds?: UUID | UUID[];
};

type PatchActivateUserRequest = ObjectWithId;

type PatchDeactivateUserRequest = ObjectWithId;

export type GetUsernamesResponse = UserNames;

type PostRemoveScaMutation = ObjectWithId;

export const userExtendedApi = portalApi.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query<GetUsersResponse, GetUsersRequest>({
      query: ({ customerHierarchyId, filters, ...rest }) => ({
        url: "users",
        params: {
          customerHierarchyId,
          ...prepareFilterParams(filters),
          ...preparePaginationParams(rest),
        },
      }),
      providesTags: [TAGS.USERS],
      transformResponse: (users: User[], meta: RTKMeta) => ({
        users,
        totalItems: Number(meta.response.headers.get("total-items")),
      }),
    }),
    getUser: build.query<User, UUID>({
      query: (userId) => `users/${userId}`,
      transformResponse: (user: User) => subject(SUBJECT_USER, user),
      providesTags: (_, __, id) => [{ type: TAGS.USER, id }],
    }),
    getUsernames: build.query<GetUsernamesResponse, GetUsernamesRequest>({
      query: ({ userIds }) => ({
        url: "users",
        method: "GET",
        params: {
          id: userIds,
          ...DEFAULT_PAGINATION,
        },
      }),
      transformResponse: (data: BasicUser[]) =>
        data.reduce<GetUsernamesResponse>(
          (names, user) => ({
            ...names,
            [user.id]: user.name,
          }),
          {}
        ),
      providesTags: [TAGS.USER_NAMES],
    }),
    resetPassword: build.mutation<UUID, UUID>({
      query: (id) => ({
        url: `/users/${id}/password/reset`,
        method: "POST",
      }),
    }),
    updateUser: build.mutation<
      string,
      { id: UUID; user: Partial<UserPatch>; options?: Record<string, unknown> }
    >({
      query: ({ id, user }) => ({
        url: `/users/${id}`,
        method: "PATCH",
        body: user,
      }),
      onQueryStarted({ id, user }, { dispatch, queryFulfilled }) {
        return pessimisticUpdate(queryFulfilled)(() => {
          dispatch(
            userExtendedApi.util.updateQueryData(
              "getUser",
              id,
              (cachedUser: User) => {
                Object.entries(user).reduce((accumulator, [key, value]) => {
                  (accumulator as any)[key] = value;

                  return accumulator;
                }, cachedUser);
              }
            )
          );
        });
      },
      invalidatesTags: [TAGS.USERS],
    }),
    activateUser: build.mutation<void, PatchActivateUserRequest>({
      query: ({ id }) => ({
        url: `users/${id}/activate`,
        method: "PATCH",
      }),
      onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        return pessimisticUpdate(queryFulfilled)(() => {
          dispatch(
            userExtendedApi.util.updateQueryData(
              "getUser",
              id,
              (cachedUser: User) => {
                cachedUser.active = true;
              }
            )
          );
        });
      },
      invalidatesTags: [TAGS.USERS],
    }),
    deactivateUser: build.mutation<void, PatchDeactivateUserRequest>({
      query: ({ id }) => ({
        url: `users/${id}/deactivate`,
        method: "PATCH",
      }),
      onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        return pessimisticUpdate(queryFulfilled)(() => {
          dispatch(
            userExtendedApi.util.updateQueryData(
              "getUser",
              id,
              (cachedUser: User) => {
                cachedUser.active = false;
              }
            )
          );
        });
      },
      invalidatesTags: [TAGS.USERS],
    }),
    deleteScaAuthentication: build.mutation<void, PostRemoveScaMutation>({
      query: ({ id }) => ({
        url: `users/${id}/sca`,
        method: "DELETE",
      }),
      onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        return pessimisticUpdate(queryFulfilled)(() => {
          dispatch(
            userExtendedApi.util.invalidateTags([
              {
                type: TAGS.USERS,
              },
            ])
          );

          dispatch(
            userExtendedApi.util.updateQueryData(
              "getUser",
              id,
              (draft: User) => {
                Object.assign(draft, { scaAuthentication: { active: false } });
              }
            )
          );
        });
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUserQuery,
  useGetUsersQuery,
  useGetUsernamesQuery,
  useResetPasswordMutation,
  useUpdateUserMutation,
  useDeleteScaAuthenticationMutation,
  useActivateUserMutation,
  useDeactivateUserMutation,
} = userExtendedApi;

export const { getUser } = userExtendedApi.endpoints;
