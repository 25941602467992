import { QUICK_FILTERS } from "../constants/Filters";

import type { UserFilters } from "../models/Filters";

export type FilterParams = {
  active?: boolean;
  voltEmployee?: boolean;
};

export function prepareFilterParams(
  filters?: Partial<UserFilters>
): FilterParams {
  const params: FilterParams = {};
  const hasActiveFilter = filters?.quickFilters?.includes(QUICK_FILTERS.ACTIVE);
  const hasInactiveFilter = filters?.quickFilters?.includes(
    QUICK_FILTERS.INACTIVE
  );
  const hasVoltEmployeeFilter = filters?.quickFilters?.includes(
    QUICK_FILTERS.VOLT_ADMIN
  );

  if (hasVoltEmployeeFilter) {
    params["voltEmployee"] = true;
  }

  if (hasActiveFilter && !hasInactiveFilter) {
    params["active"] = true;
  }

  if (hasInactiveFilter && !hasActiveFilter) {
    params["active"] = false;
  }

  return params;
}
