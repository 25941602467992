import { array, boolean, object, string } from "utils/yup";

import type { InferType } from "utils/yup";

export interface AisRedirectUrls
  extends InferType<typeof AID_REDIRECT_URLS_SCHEMA> {}

export const AID_REDIRECT_URLS_SCHEMA = object({
  cancelUrl: string().required(),
  failureUrl: string().required(),
  successUrl: string().required(),
});

export interface Client extends InferType<typeof CLIENT_SCHEMA> {}

export const CLIENT_SCHEMA = object({
  id: string().uuid().required(),
  active: boolean().required(),
  name: string().required(),
  paymentCancelUrl: string().required().nullable(),
  paymentFailureUrl: string().required().nullable(),
  paymentPendingUrl: string().required().nullable(),
  paymentSuccessUrl: string().required().nullable(),
  secret: string().required(),
  signingKey: string().required(),
  aisRedirectUrls: AID_REDIRECT_URLS_SCHEMA.optional()
    .nullable()
    .default(undefined),
  //TODO(FBI-550): Please replace below schema with picks from customers module
  customer: object({
    active: boolean().required(),
    id: string().simplifiedUUID().required(),
    name: string().required(),
  }).required(),
});

export const CLIENTS_ARRAY_SCHEMA = array().of(CLIENT_SCHEMA).required();

export const PROPERTY_ACTIVE = "active";
export const PROPERTY_REDIRECT_SIGNATURE = "redirectSignature";
export const PROPERTY_SECRET = "secret";
