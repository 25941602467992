/*eslint perfectionist/sort-objects: "error"*/

export const enGb = {
  account: "Account",
  accountDetailsDescription:
    "Bank account details like IBAN and Swift code (BIC). Account number, sort code, BSB or PIX key can be displayed for particular currencies.",
  accountIdentification: "Account identification",
  accountIdentificationAndBeneficiaryInformation:
    "Account identification and beneficiary information.",
  accountName: "Account name",
  accountStatus: "Account status",
  active: "Active",
  add: "Add",
  addANewBankAccount: "Add a new bank account",
  addNewAccount: "Add new account",
  allShoppersPaymentsWillBeInitiatedAsSepaStandard:
    "All shoppers' payments will be initiated as a standard SEPA to this account",
  areYouSureToProceedDisablingSepa:
    "Are you sure you want to proceed? By disabling SEPA instant on this account, EUR payments will no longer be processed using SEPA instant scheme.",
  backToAllBankAccount: "Back to all bank accounts",
  backToBankAccounts: "Back to bank accounts",
  bankAccount: "Bank account",
  bankAccountCountryAndCurrency: "Bank account country and currency.",
  bankAccountDetails: "Bank account details",
  bankAccountInheritance: "Bank account inheritance",
  bankAccountNotFound: "Bank account not found",
  bankAccounts: "Bank accounts",
  bankAccountWasCreated: "Bank account was created",
  bankName: "Bank name",
  bsb: "BSB",
  cardSubtitleAccountsDetails:
    "Enter your account number (IBAN) and Swift code (BIC) to receive payments into this account",
  cardSubtitleAccountsDetailsAUD:
    "Enter your Bank State Branch number (BSB) and Account number to receive payments into this account",
  cardSubtitleAccountsDetailsGBP:
    "Creating a bank account in domestic currency in the United Kingdom requires providing the account number and sort code",
  cardSubtitleAccountsDetailsPIX:
    "Provide PIX identification key to receive domestic Brazilian settlement on this account",
  cardSubtitleAccountsDetailsUK:
    "Enter the international account number (IBAN) and Swift code (BIC)to receive payments into this account",
  cardTitleAccountDetails: "Account details",
  createAccount: "Create account",
  currency: "Currency",
  currentlyActiveBankAccountIn: "Currently active bank account in {{currency}}",
  customer: "Customer",
  customerWillNotReceiveInstantPaymentsWithoutPermission:
    "Customer will not receive incoming instant payments within SEPA on this bank account. If you believe this to be an error, please contact your account manager",
  dependentMerchantAccounts: "Dependent merchant accounts",
  disableSepaPayments: "Disable SEPA instant payments",
  edit: "Edit",
  editAccount: "Edit account",
  enterMoreThanOneCharacter: "Enter more than one character",
  forTheChangeToAppearInTheSystem:
    "For the change to appear in the system you have to wait for approval from another team member.  You can schedule an effective date for this request.",
  givenBankAccountCouldNotBeFound:
    "Given bank account could not be found. Make sure you are on correct mode (production/sandbox).",
  inactive: "Inactive",
  incomingSepaPaymentsStatus: "SEPA instant",
  invalidOfficialName: "Invalid official name",
  loading: "Loading...",
  locationAndCurrency: "Location and currency",
  newBankAccount: "New bank account",
  noOptions: "No options",
  pixKey: "PIX Key",
  pixTypeCNPJ: "CNPJ",
  pixTypeEmail: "E-mail",
  pixTypePhoneNumber: "Phone number",
  pixTypeRandomKey: "Random key",
  pixVerificationKeyToReceivePayments:
    "PIX verification key used to receive payments into this account.",
  pleaseConfirmYourRequestUsingTheSelectedSCAMethod:
    "Please confirm your request using the selected SCA method.",
  pleaseProvideCustomerAndCurrency:
    "Please provide customer and currency to enable automated account name generator.",
  requestIdHasBeenGenerated: "Request {{id}} has been generated.",
  SCARequiredToAddANewAccount: "SCA required to add a new account",
  select: "Select",
  sepaDisabled:
    "Disabled - Account does not support incoming SEPA instant payments",
  sepaEnabled: "Enabled - Account supports incoming SEPA instant payments",
  sepaHasBeenDisabled: "SEPA instant has been disabled",
  sepaHasBeenEnabled: "SEPA instant has been enabled",
  settlementSettings: "Settlement settings",
  setupSCA: "Set up SCA",
  status: "Status",
  statusBankAccountActivated: "Enabled - Payment can be sent to this account",
  statusBankAccountDeactivated:
    "Disabled - Payment can't be sent to this account",
  submittingRequest: "Submitting request",
  swiftCode: "Swift code (BIC)",
  theseChangesMayImpactFlow: "These changes may impact cash flow",
  toAddANewAccountYouNeedToSetUpSCA:
    "To add a new account you need to set up SCA",
  uniqueIdForApi: "Unique ID for API",
  updateAccount: "Update account",
  wheneverPossibleShoppersPaymentsWillBeInitiatedAsSepaInstant:
    "Whenever possible, shoppers' payments will be initiated as a SEPA instant to this account",
  workflows: "Workflows",
  youAreCreatingANewAccountIn:
    "You are creating a new account in {{currency}}, which will replace the existing account dedicated specifically to this currency.",
  youCanNotCreateBankAccount:
    "You cannot create a bank account in this currency as there is already a pending request for this customer",
};
