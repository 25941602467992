import type { Customer } from "modules/customers/models/Customer";

import type { UUID } from "./uuid";

export const findChildrenIds = (customers: Customer[]): UUID[] =>
  customers
    .map((customer) => [
      customer.id,
      ...findChildrenIds(customer.children ?? []),
    ])
    .reduce((acc, ids) => {
      acc.push(...ids);

      return acc;
    }, []);
