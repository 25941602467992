import { InfoIcon, SuccessIcon } from "@volt_developers/components";

import type {
  ButtonDeprecatedProps,
  ToastDeprecatedProps,
} from "@volt_developers/components";

export type ToastVariant = ToastDeprecatedProps["variant"];
type ButtonVariant = ButtonDeprecatedProps["variant"];
type ToastButtonColor = "accent" | "danger" | "default";
type ToastGhostButtonColor = "ghost-blue" | "ghost";
type ToastButtonVariant = Extract<ButtonVariant, "outlined" | "solid">;

const buttonColorMap = new Map<ToastVariant, ToastButtonColor>([
  ["default", "default"],
  ["success", "accent"],
  ["failure", "danger"],
  ["info", "default"],
]);

const buttonVariantMap = new Map<ToastVariant, ToastButtonVariant>([
  ["default", "solid"],
  ["success", "outlined"],
  ["failure", "outlined"],
  ["info", "outlined"],
]);

const buttonGhostColorMap = new Map<ToastVariant, ToastGhostButtonColor>([
  ["default", "ghost-blue"],
  ["success", "ghost"],
  ["failure", "ghost"],
  ["info", "ghost"],
]);

export function useToast(variant: ToastVariant) {
  return {
    buttonColor: buttonColorMap.get(variant),
    buttonVariant: buttonVariantMap.get(variant),
    buttonGhostColor: buttonGhostColorMap.get(variant),
    iconComponent: variant === "success" ? <SuccessIcon /> : <InfoIcon />,
  };
}
