import { COMMON_TRANSLATION_KEYS } from "constants/commonTranslationsKeys";
import { ACTION_CREATE, ACTION_LIST } from "models/role/action";
import { SUBJECT_CIRCUIT_BREAKER } from "models/role/subject";
import { lazyRetry } from "utils/lazyRetry";

import { PAGE_URLS } from "../constants/PageUrls";

import type { Route } from "routes/RouteModel";

const Blocklist = lazyRetry(
  () => import("../pages/Blocklist"),
  "CircuitBreakerBlocklist"
);

const BlocklistCreate = lazyRetry(
  () => import("../pages/BlocklistCreate"),
  "CircuitBreakerBlocklistCreate"
);

export const blocklistRoute: Route = {
  component: Blocklist,
  title: [COMMON_TRANSLATION_KEYS.blocklist],
  path: PAGE_URLS.blocklist,
  can: {
    do: ACTION_LIST,
    on: SUBJECT_CIRCUIT_BREAKER,
  },
  children: [
    {
      title: [
        COMMON_TRANSLATION_KEYS.blocklist,
        COMMON_TRANSLATION_KEYS.newItems,
      ],
      path: PAGE_URLS.blocklistCreate,
      component: BlocklistCreate,
      can: {
        do: ACTION_CREATE,
        on: SUBJECT_CIRCUIT_BREAKER,
      },
    },
  ],
};
