import { CUSTOMER_STATUS_ACTIVE } from "../constants/CustomerStatus";

import type { CustomerFiltersType } from "../models/CustomerFilter";

export function prepareCustomerFilters({
  quickFilters,
  ...filters
}: Partial<CustomerFiltersType> = {}) {
  if (quickFilters?.length === 1) {
    const isActive = quickFilters.includes(CUSTOMER_STATUS_ACTIVE);

    return {
      ...filters,
      active: isActive,
    };
  }

  return filters;
}
