import { Skeleton } from "@mui/material";
import { Dialog } from "@volt_developers/components";
import { getSpacing } from "@volt_developers/foundations";

import { TEST_IDS } from "../../constants/testIds";

export function LicenceAgreementContentSkeleton() {
  return (
    <Dialog.Content
      data-testid={TEST_IDS.termsAndConditionsContent}
      sx={{ py: getSpacing(5) }}
    >
      <Skeleton height={SKELETON_HEIGHT} width="70%" />
      <Skeleton height={SKELETON_HEIGHT} width="80%" />
      <Skeleton height={SKELETON_HEIGHT} width="60%" />
      <Skeleton height={SKELETON_HEIGHT} width="90%" />
      <Skeleton height={SKELETON_HEIGHT} width="80%" />
      <Skeleton height={SKELETON_HEIGHT} width="43%" />
      <Skeleton height={SKELETON_HEIGHT} width="55%" />
      <Skeleton height={SKELETON_HEIGHT} width="75%" />
      <Skeleton height={SKELETON_HEIGHT} width="80%" />
      <Skeleton height={SKELETON_HEIGHT} width="85%" />
      <Skeleton height={SKELETON_HEIGHT} width="70%" />
      <Skeleton height={SKELETON_HEIGHT} width="60%" />
      <Skeleton height={SKELETON_HEIGHT} width="50%" />
    </Dialog.Content>
  );
}

const SKELETON_HEIGHT = 17;
