import * as Yup from "yup";

import { simplifiedUUID } from "utils/patterns";

declare module "yup" {
  export interface StringSchema {
    simplifiedUUID(): this;
  }
}

Yup.addMethod<Yup.StringSchema>(Yup.string, "simplifiedUUID", function () {
  return this.matches(simplifiedUUID);
});
