import {
  useIsProductionSelector,
  useIsSandboxSelector,
} from "../selectors/useAppEnvironmentSelector";

export function useEnvironmentStatus() {
  return {
    isProduction: useIsProductionSelector(),
    isSandbox: useIsSandboxSelector(),
  };
}
