import { COMMON_TRANSLATION_KEYS } from "constants/commonTranslationsKeys";
import {
  ACTION_CREATE,
  ACTION_DETAILS,
  ACTION_LIST,
  ACTION_UPDATE,
} from "models/role/action";
import { SUBJECT_CLIENT } from "models/role/subject";
import { lazyRetry } from "utils/lazyRetry";
import { lazyRetryWithPreload } from "utils/lazyRetryWithPreload";

import { PAGE_URLS } from "../constants/PageUrls";

import type { Route } from "routes/RouteModel";

const ApplicationCreate = lazyRetry(
  () => import("../pages/Create"),
  "ApplicationManage"
);

const ApplicationDetails = lazyRetry(
  () => import("../pages/Details"),
  "ApplicationDetails"
);

const ApplicationEdit = lazyRetry(
  () => import("../pages/Edit"),
  "ApplicationManage"
);

const ApplicationList = lazyRetryWithPreload(
  () => import("../pages/List"),
  "ApplicationList"
);

export const applicationCreateRoute: Route = {
  path: "/configuration/applications/create",
  title: [COMMON_TRANSLATION_KEYS.applications, COMMON_TRANSLATION_KEYS.new],
  component: ApplicationCreate,
  can: {
    do: ACTION_CREATE,
    on: SUBJECT_CLIENT,
  },
};

export const applicationRoutes: Route = {
  can: {
    do: ACTION_LIST,
    on: SUBJECT_CLIENT,
  },
  children: [
    applicationCreateRoute,
    {
      path: "/configuration/applications/:id/edit",
      title: [
        COMMON_TRANSLATION_KEYS.applications,
        COMMON_TRANSLATION_KEYS.edit,
      ],
      component: ApplicationEdit,
      can: {
        do: ACTION_UPDATE,
        on: SUBJECT_CLIENT,
      },
    },
    {
      can: {
        do: ACTION_DETAILS,
        on: SUBJECT_CLIENT,
      },
      component: ApplicationDetails,
      hasNestedSwitch: true,
      title: [
        COMMON_TRANSLATION_KEYS.applications,
        COMMON_TRANSLATION_KEYS.details,
      ],
      path: PAGE_URLS.details,
    },
  ],
  component: ApplicationList,
  hasNestedSwitch: true,
  hidden: false,
  title: [COMMON_TRANSLATION_KEYS.applications, COMMON_TRANSLATION_KEYS.list],
  path: PAGE_URLS.list,
  showChildrenInSidebar: false,
};
