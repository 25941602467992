import { getRouteWithPersistedFilterParams } from "modules/common/utils/getRouteWithFilterParams/getRouteWithFilterParams";

import {
  BLOCKLIST_FILTERS_PERSISTED_KEY,
  INITIAL_FILTERS_CONFIGURATION,
} from "./Filters";

const BASE_URL = "/circuit-breaker";
const BASE_URL_ALLOWLIST = `${BASE_URL}/allowlist`;
const BASE_URL_BLOCKLIST = `${BASE_URL}/blocklist`;

export const PAGE_URLS = {
  allowlist: `${BASE_URL_ALLOWLIST}`,
  allowlistCreate: `${BASE_URL_ALLOWLIST}/create`,
  blocklist: `${BASE_URL_BLOCKLIST}`,
  getBlocklist: () =>
    getRouteWithPersistedFilterParams(
      PAGE_URLS.blocklist,
      BLOCKLIST_FILTERS_PERSISTED_KEY,
      INITIAL_FILTERS_CONFIGURATION
    ),
  blocklistCreate: `${BASE_URL_BLOCKLIST}/create`,
  riskRules: `${BASE_URL}/risk-rules`,
} as const;

export const CIRCUIT_BREAKER_DOCS_URL = "https://docs.volt.io/circuit-breaker/";
