import { keyframes, styled } from "@mui/material";

import { COLORS } from "./colors";

export function AnimatedBackground() {
  return (
    <FullScreenLayout>
      <BlurContainer>
        <SmallCircle />
        <BigCircle />
      </BlurContainer>
    </FullScreenLayout>
  );
}

const FullScreenLayout = styled("div")({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  overflow: "hidden",
});

const ESTIMATED_CONTENT_SIZE = 500 as const;
const SMALL_CIRCLE_SIZE = 410 as const;
const SMALL_CIRCLE_BLUR = 75 as const;
const BIG_CIRCLE_SIZE = 970 as const;
const BIG_CIRCLE_BLUR = 175 as const;

const moveBlurAround = keyframes({
  "0%": {
    transform: `translate(${(-1 * ESTIMATED_CONTENT_SIZE) / 2}px, ${(-1 * ESTIMATED_CONTENT_SIZE) / 2}px)`,
  },

  "25%": {
    transform: `translate(${ESTIMATED_CONTENT_SIZE / 2}px, ${(-1 * ESTIMATED_CONTENT_SIZE) / 2}px)`,
  },

  "50%": {
    transform: `translate(${ESTIMATED_CONTENT_SIZE / 2}px, ${ESTIMATED_CONTENT_SIZE / 2}px)`,
  },

  "75%": {
    transform: `translate(${(-1 * ESTIMATED_CONTENT_SIZE) / 2}px, ${ESTIMATED_CONTENT_SIZE / 2}px)`,
  },

  "100%": {
    transform: `translate(${(-1 * ESTIMATED_CONTENT_SIZE) / 2}px, ${(-1 * ESTIMATED_CONTENT_SIZE) / 2}px)`,
  },
});

const BlurContainer = styled("div")`
  display: grid;
  place-items: center;
  top: calc(50% - ${BIG_CIRCLE_SIZE / 2}px);
  animation: ${moveBlurAround} 30s linear infinite;
`;

const SmallCircle = styled("div")({
  width: SMALL_CIRCLE_SIZE,
  height: SMALL_CIRCLE_SIZE,
  borderRadius: "100%",
  background: COLORS.smallBlur,
  filter: `blur(${SMALL_CIRCLE_BLUR}px)`,
  gridColumn: "1/1",
  gridRow: "1/1",
});

const BigCircle = styled("div")({
  width: BIG_CIRCLE_SIZE,
  height: BIG_CIRCLE_SIZE,
  borderRadius: "100%",
  background: COLORS.bigBlur,
  filter: `blur(${BIG_CIRCLE_BLUR}px)`,
  gridColumn: "1/1",
  gridRow: "1/1",
});
