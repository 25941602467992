import { applicationRoutes } from "modules/applications/routes";
import { routes as accountsRoutes } from "modules/bank-accounts/routes";
import { customersRoutes } from "modules/customers/routes";
import { userManagementRoutes } from "modules/user-management/routes";

import type { Route } from "../RouteModel";

export const configurationRoutes: Route = {
  // title for this path is defined in children routes
  path: "/configuration",
  showChildrenInSidebar: true,
  children: [
    applicationRoutes,
    accountsRoutes,
    customersRoutes,
    userManagementRoutes,
  ],
};
