import {
  STATUS_AUTHORISED_BY_USER,
  STATUS_BANK_REDIRECT,
  STATUS_CANCELLED_BY_USER,
  STATUS_COMPLETED,
  STATUS_DELAYED_AT_BANK,
  STATUS_ERROR_AT_BANK,
  STATUS_FAILED,
  STATUS_GROUP_CASH_MANAGEMENT,
  STATUS_GROUP_FAILED,
  STATUS_GROUP_INITIATED,
  STATUS_GROUP_OTHER,
  STATUS_GROUP_PENDING,
  STATUS_GROUP_REQUESTED,
  STATUS_NEW_PAYMENT,
  STATUS_REFUSED_BY_BANK,
} from "modules/payments/constants/paymentStatuses";

/**
 * @deprecated use translations in the payments module
 */
export const payments = {
  Date: "Date",
  ID: "ID",
  Reference: "Reference",
  Amount: "Amount",
  Currency: "Currency",
  CurrencyAbv: "CCY",
  Status: "Status",
  "Transaction details": "Transaction details",
  "Transaction header": "Transaction header",
  Timeline: "Timeline",
  "Payment details": "Payment details",
  "Volt ID": "Volt ID",
  "Instruction received": "Instruction received",
  "Initiating application": "Initiating application",
  "Merchant bank account": "Merchant bank account",
  "Payer's bank": "Payer's bank",
  "Your reference": "Your reference",
  "Account ending": "Account ending {{accountNumber}}",
  swiftCode: "Swift code (BIC)",
  iban: "IBAN",
  sortCode: "Sort code",
  accountNumber: "Account number",
  accountName: "Account name",
  "Start date": "Start date",
  "End date": "End date",
  "Search by Transaction ID or Reference":
    "Search by Transaction ID or Reference",
  selected: "{{count}} selected",
  day: "{{count}} day",
  day_plural: "{{count}} days",

  // statuses
  [STATUS_NEW_PAYMENT]: "New payment",
  [STATUS_BANK_REDIRECT]: "Bank redirect",
  [STATUS_CANCELLED_BY_USER]: "Cancelled by user",
  [STATUS_FAILED]: "Failed",
  [STATUS_REFUSED_BY_BANK]: "Refused by bank",
  [STATUS_ERROR_AT_BANK]: "Error at bank",
  [STATUS_AUTHORISED_BY_USER]: "Authorized by user",
  [STATUS_COMPLETED]: "Completed",
  [STATUS_DELAYED_AT_BANK]: "Delayed at bank",

  // status groups
  [STATUS_GROUP_REQUESTED]: "Requested",
  [STATUS_GROUP_INITIATED]: "Initiated",
  [STATUS_GROUP_PENDING]: "Pending",
  [STATUS_GROUP_FAILED]: "Failed",
  [STATUS_GROUP_OTHER]: "Other",
  [STATUS_GROUP_CASH_MANAGEMENT]: "Cash management",

  // filters
  "Date range": "Date range",
  Anytime: "Anytime",
};
