import {
  DashboardIcon,
  FolderIcon,
  SettingsCogIcon,
} from "@volt_developers/components";

import {
  AdministrationIcon,
  CheckoutIcon,
  CircuitBreakerOutlinedIcon,
  ConnectIcon,
  VoltAccountsIcon,
} from "assets/productIcons";
import { COMMON_TRANSLATION_KEYS } from "constants/commonTranslationsKeys";
import { ACTION_DETAILS, ACTION_LIST } from "models/role/action";
import {
  SUBJECT_ACCOUNT,
  SUBJECT_CASH_MANAGEMENT,
  SUBJECT_CASH_MANAGEMENT_SETTLEMENT,
  SUBJECT_CASH_MANAGEMENT_VOLT_ACCOUNT_TRANSACTIONS,
  SUBJECT_CASH_MANAGEMENT_VOLT_ACCOUNTS,
  SUBJECT_CHECKOUT,
  SUBJECT_CIRCUIT_BREAKER,
  SUBJECT_CLIENT,
  SUBJECT_CLOSED_LOOP_PAYOUTS,
  SUBJECT_CUSTOMER,
  SUBJECT_OPEN_LOOP_PAYOUTS,
  SUBJECT_PAYMENT,
  SUBJECT_REFUND,
  SUBJECT_USER,
  SUBJECT_WORKFLOWS,
} from "models/role/subject";
import { PAGE_URLS as APPLICATIONS_PAGE_URLS } from "modules/applications/constants/PageUrls";
import { PAGE_URLS as ACCOUNTS_PAGE_URLS } from "modules/bank-accounts/constants/PageUrls";
import { PAGE_URLS as CHECKOUT_PAGE_URLS } from "modules/checkout/constants/PageUrls";
import { PAGE_URLS as CIRCUIT_BREAKER_PAGE_URLS } from "modules/circuit-breaker/constants/PageUrls";
import {
  allowlistRoute,
  circuitBreakerRoutes,
  riskRulesRoute,
} from "modules/circuit-breaker/routes";
import { pruneArray } from "modules/common/utils/pruneArray";
import { connectRoutes } from "modules/connect/routes";
import { settlementsRoutes } from "modules/connect/routes/settlements";
import { customersRoutes } from "modules/customers/routes";
import { dashboardRoutes } from "modules/dashboard/routes";
import { PAGE_URLS as PAYMENTS_PAGE_URLS } from "modules/payments/constants/PageUrls";
import { payoutsRoutes } from "modules/payouts/routes";
import { refundsRoutes } from "modules/refunds/routes";
import { userManagementRoutes } from "modules/user-management/routes";
import { PAGE_URLS as VOLT_ACCOUNTS_PAGE_URLS } from "modules/volt-accounts/constants/PageUrls";
import { useVoltAccountsFeatureFlag } from "modules/volt-accounts/hooks/useVoltAccountsFeatureFlag";
import { workflowsRoutes } from "modules/workflows/routes";
import { administrationRoutes } from "routes/administration";
import { configurationRoutes } from "routes/configuration";

import { TRANSACTION_PATH } from "../../constants/Payments";
import { SIDEBAR_TEST_IDS } from "../../constants/TestIds";
import { SIDEBAR_IDS } from "./SidebarIds";

import type { ReactNode } from "react";

import type { PermissionObject } from "../../types/permissions";

export interface SidebarConfigItem {
  id: string;
  permissions: PermissionObject[];
  title: string;
  children?: SidebarConfigItem[];
  "data-testid"?: string;
  icon?: ReactNode;
  isActive?: (path: string, activePath: string) => boolean;
  permissionOneOf?: boolean;
  url?: string;
}

export type DirtySidebarConfigItem = SidebarConfigItem | null;

export function useSidebarConfig() {
  const hasVoltAccountsFeatureFlag = useVoltAccountsFeatureFlag();

  const dirtySidebarConfig: DirtySidebarConfigItem[] = [
    {
      id: SIDEBAR_IDS.dashboard,
      title: COMMON_TRANSLATION_KEYS.Dashboard,
      url: dashboardRoutes.path,
      isActive,
      icon: <DashboardIcon shouldScale />,
      "data-testid": SIDEBAR_TEST_IDS.DASHBOARD,
      permissions: [],
    },
    {
      id: SIDEBAR_IDS.transactions,
      title: COMMON_TRANSLATION_KEYS.transactions,
      url: TRANSACTION_PATH,
      isActive,
      icon: <FolderIcon shouldScale />,
      "data-testid": SIDEBAR_TEST_IDS.TRANSACTIONS,
      permissionOneOf: true,
      permissions: [
        {
          do: ACTION_LIST,
          on: SUBJECT_PAYMENT,
        },
        {
          do: ACTION_LIST,
          on: SUBJECT_CLOSED_LOOP_PAYOUTS,
        },
        {
          do: ACTION_LIST,
          on: SUBJECT_OPEN_LOOP_PAYOUTS,
        },
      ],
      children: pruneArray([
        {
          id: SIDEBAR_IDS.transactionsPayments,
          title: COMMON_TRANSLATION_KEYS.payments,
          url: PAYMENTS_PAGE_URLS.getList(),
          isActive,
          "data-testid": SIDEBAR_TEST_IDS.PAYMENTS,
          permissions: [
            {
              do: ACTION_LIST,
              on: SUBJECT_PAYMENT,
            },
          ],
        },
        {
          id: SIDEBAR_IDS.transactionsRefunds,
          title: COMMON_TRANSLATION_KEYS.refunds,
          url: refundsRoutes.path,
          isActive,
          "data-testid": SIDEBAR_TEST_IDS.REFUNDS,
          permissions: [
            {
              do: ACTION_LIST,
              on: SUBJECT_REFUND,
            },
          ],
        },
        {
          id: SIDEBAR_IDS.transactionsPayouts,
          title: COMMON_TRANSLATION_KEYS.payouts,
          url: payoutsRoutes.path,
          isActive,
          "data-testid": SIDEBAR_TEST_IDS.PAYOUTS,
          permissionOneOf: true,
          permissions: [
            {
              do: ACTION_LIST,
              on: SUBJECT_CLOSED_LOOP_PAYOUTS,
            },
            {
              do: ACTION_LIST,
              on: SUBJECT_OPEN_LOOP_PAYOUTS,
            },
          ],
        },
        {
          id: SIDEBAR_IDS.transactionsSettlements,
          title: COMMON_TRANSLATION_KEYS.settlements,
          url: settlementsRoutes[0].path,
          isActive,
          "data-testid": SIDEBAR_TEST_IDS.SETTLEMENTS,
          permissions: [
            {
              do: ACTION_LIST,
              on: SUBJECT_CASH_MANAGEMENT_SETTLEMENT,
            },
          ],
        },
      ]),
    },
    hasVoltAccountsFeatureFlag
      ? {
          id: SIDEBAR_IDS.voltAccounts,
          title: COMMON_TRANSLATION_KEYS.accountsSidebarTitle,
          url: VOLT_ACCOUNTS_PAGE_URLS.list,
          isActive,
          icon: <VoltAccountsIcon />,
          "data-testid": SIDEBAR_TEST_IDS.ACCOUNTS,
          permissionOneOf: true,
          permissions: [
            {
              do: ACTION_LIST,
              on: SUBJECT_CASH_MANAGEMENT,
            },
            {
              do: ACTION_LIST,
              on: SUBJECT_CASH_MANAGEMENT_VOLT_ACCOUNTS,
            },
            {
              do: ACTION_LIST,
              on: SUBJECT_CASH_MANAGEMENT_VOLT_ACCOUNT_TRANSACTIONS,
            },
          ],
          children: [
            {
              id: SIDEBAR_IDS.voltAccountsOverview,
              title: COMMON_TRANSLATION_KEYS.overview,
              url: VOLT_ACCOUNTS_PAGE_URLS.list,
              isActive: (path: string, activePath: string) => {
                if (activePath.startsWith(connectRoutes.path)) {
                  return false;
                }

                return isActive(path, activePath);
              },
              "data-testid": SIDEBAR_TEST_IDS.VOLT_ACCOUNTS_OVERVIEW,
              permissionOneOf: true,
              permissions: [
                {
                  do: ACTION_LIST,
                  on: SUBJECT_CASH_MANAGEMENT_VOLT_ACCOUNTS,
                },
                {
                  do: ACTION_LIST,
                  on: SUBJECT_CASH_MANAGEMENT_VOLT_ACCOUNT_TRANSACTIONS,
                },
              ],
            },
            {
              id: SIDEBAR_IDS.voltAccountsConnect,
              title: COMMON_TRANSLATION_KEYS.connect,
              url: connectRoutes.path,
              isActive,
              "data-testid": SIDEBAR_TEST_IDS.VOLT_ACCOUNTS_CONNECT,
              permissions: [
                {
                  do: ACTION_LIST,
                  on: SUBJECT_CASH_MANAGEMENT,
                },
              ],
            },
          ],
        }
      : null,
    !hasVoltAccountsFeatureFlag
      ? {
          id: SIDEBAR_IDS.connect,
          title: COMMON_TRANSLATION_KEYS.connect,
          url: connectRoutes.path,
          isActive,
          icon: <ConnectIcon />,
          "data-testid": SIDEBAR_TEST_IDS.CONNECT,
          permissions: [
            {
              do: ACTION_LIST,
              on: SUBJECT_CASH_MANAGEMENT,
            },
          ],
        }
      : null,
    {
      id: SIDEBAR_IDS.checkoutBranding,
      title: COMMON_TRANSLATION_KEYS.checkoutBranding,
      url: CHECKOUT_PAGE_URLS.details,
      isActive,
      icon: <CheckoutIcon />,
      "data-testid": SIDEBAR_TEST_IDS.CHECKOUT_BRANDING,
      permissions: [
        {
          do: ACTION_DETAILS,
          on: SUBJECT_CHECKOUT,
        },
      ],
    },
    {
      id: SIDEBAR_IDS.circuitBreaker,
      title: COMMON_TRANSLATION_KEYS.circuitBreaker,
      icon: <CircuitBreakerOutlinedIcon />,
      url: circuitBreakerRoutes?.path,
      isActive,
      "data-testid": SIDEBAR_TEST_IDS.CIRCUIT_BREAKER,
      permissions: [
        {
          do: ACTION_LIST,
          on: SUBJECT_CIRCUIT_BREAKER,
        },
      ],
      children: [
        {
          id: SIDEBAR_IDS.circuitBreakerAllowList,
          title: COMMON_TRANSLATION_KEYS.allowlist,
          url: allowlistRoute.path,
          isActive,
          "data-testid": SIDEBAR_TEST_IDS.ALLOWLIST,
          permissions: [
            {
              do: ACTION_LIST,
              on: SUBJECT_CIRCUIT_BREAKER,
            },
          ],
        },
        {
          id: SIDEBAR_IDS.circuitBreakerBlockList,
          title: COMMON_TRANSLATION_KEYS.blocklist,
          url: CIRCUIT_BREAKER_PAGE_URLS.getBlocklist(),
          isActive,
          "data-testid": SIDEBAR_TEST_IDS.BLOCKLIST,
          permissions: [
            {
              do: ACTION_LIST,
              on: SUBJECT_CIRCUIT_BREAKER,
            },
          ],
        },
        {
          id: SIDEBAR_IDS.circuitBreakerRiskRules,
          title: COMMON_TRANSLATION_KEYS.riskRules,
          url: riskRulesRoute.path,
          isActive,
          "data-testid": SIDEBAR_TEST_IDS.RISK_RULES,
          permissions: [
            {
              do: ACTION_LIST,
              on: SUBJECT_CIRCUIT_BREAKER,
            },
          ],
        },
      ],
    },
    workflowsSidebarConfig,
    {
      id: SIDEBAR_IDS.configuration,
      title: COMMON_TRANSLATION_KEYS.configuration,
      icon: <SettingsCogIcon shouldScale />,
      url: configurationRoutes.path,
      isActive,
      "data-testid": SIDEBAR_TEST_IDS.CONFIGURATION,
      permissions: [
        {
          do: ACTION_LIST,
          on: SUBJECT_CLIENT,
        },
        {
          do: ACTION_LIST,
          on: SUBJECT_ACCOUNT,
        },
        {
          do: ACTION_LIST,
          on: SUBJECT_CUSTOMER,
        },
        {
          do: ACTION_LIST,
          on: SUBJECT_USER,
        },
      ],
      permissionOneOf: true,
      children: [
        {
          id: SIDEBAR_IDS.configurationApplications,
          title: COMMON_TRANSLATION_KEYS.Applications,
          url: APPLICATIONS_PAGE_URLS.getList(),
          isActive,
          "data-testid": SIDEBAR_TEST_IDS.APPLICATIONS,
          permissions: [
            {
              do: ACTION_LIST,
              on: SUBJECT_CLIENT,
            },
          ],
        },
        {
          id: SIDEBAR_IDS.configurationBankAccount,
          title: COMMON_TRANSLATION_KEYS["Bank accounts"],
          url: ACCOUNTS_PAGE_URLS.getList(),
          isActive,
          "data-testid": SIDEBAR_TEST_IDS.ACCOUNTS,
          permissions: [
            {
              do: ACTION_LIST,
              on: SUBJECT_ACCOUNT,
            },
          ],
        },
        {
          id: SIDEBAR_IDS.configurationCustomers,
          title: COMMON_TRANSLATION_KEYS.customers,
          url: customersRoutes.path,
          isActive,
          "data-testid": SIDEBAR_TEST_IDS.CUSTOMERS,
          permissions: [
            {
              do: ACTION_LIST,
              on: SUBJECT_CUSTOMER,
            },
          ],
        },
        {
          id: SIDEBAR_IDS.configurationUsers,
          title: COMMON_TRANSLATION_KEYS.Users,
          url: userManagementRoutes.path,
          isActive,
          "data-testid": SIDEBAR_TEST_IDS.USERS,
          permissions: [
            {
              do: ACTION_LIST,
              on: SUBJECT_USER,
            },
          ],
        },
      ],
    },
  ];

  const sidebarConfig = dirtySidebarConfig.filter(
    (item) => item
  ) as SidebarConfigItem[];

  return {
    sidebarConfig,
  };
}

function isActive(path: string, activePath: string) {
  if (path === "/") {
    return path === activePath;
  }

  return activePath.startsWith(path);
}

const workflowsSidebarConfig: SidebarConfigItem = {
  id: SIDEBAR_IDS.administration,
  title: COMMON_TRANSLATION_KEYS.Administration,
  icon: <AdministrationIcon />,
  url: administrationRoutes.path,
  isActive,
  "data-testid": SIDEBAR_TEST_IDS.ADMINISTRATION,
  permissions: [
    {
      do: ACTION_LIST,
      on: SUBJECT_WORKFLOWS,
    },
  ],
  permissionOneOf: true,
  children: [
    {
      id: SIDEBAR_IDS.administrationWorkflows,
      title: COMMON_TRANSLATION_KEYS.Workflows,
      url: workflowsRoutes.path,
      isActive,
      "data-testid": SIDEBAR_TEST_IDS.WORKFLOWS,
      permissions: [
        {
          do: ACTION_LIST,
          on: SUBJECT_WORKFLOWS,
        },
      ],
    },
  ],
};
