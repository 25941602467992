import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import {
  theme,
  ToastDeprecatedContainer,
  Utility,
} from "@volt_developers/components";
import React, { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";

import { AbilityContext } from "context/Ability.context";
import { useDefineAbility } from "hooks/useDefineAbility";
import { SCAProvider } from "modules/auth/context/SCAContext";
import { useResetPersistedFilters } from "modules/common/hooks/usePersistedFilters";
import { MainSwitch } from "routes/switches/MainSwitch";
import { init } from "store/appSlice";

export function App() {
  const dispatch = useDispatch();

  useResetPersistedFilters();

  //We want this to run before children useEffects
  //that are not delayed using lazy loading
  useLayoutEffect(() => {
    dispatch(init());
  }, [dispatch]);

  const ability = useDefineAbility();

  return (
    <React.Fragment>
      <Utility.Normalize />
      <AbilityContext.Provider value={ability}>
        <SCAProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <ToastDeprecatedContainer />
                <MainSwitch />
              </LocalizationProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </SCAProvider>
      </AbilityContext.Provider>
    </React.Fragment>
  );
}
