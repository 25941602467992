import { createAction, createSlice } from "@reduxjs/toolkit";

import { listFactory } from "store/listFactory";

import type { PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "store";
import type { UUID } from "utils";

import type {
  BlockRule,
  BlockRulePostBody,
  BlockRulesQueryParams,
  SelectedBlockRule,
} from "../../models/BlockRules";

const { slicePart, selectors } = listFactory<BlockRule>(
  "circuitBreakerBlocklist"
);

const initialState: {
  allItems: number;
  selectedItems: SelectedBlockRule[];
} = {
  ...slicePart.initialState,
  allItems: 0,
  selectedItems: [],
};

export const slice = createSlice({
  ...slicePart,
  initialState,
  reducers: {
    ...slicePart.reducers,
    toggleSelectedItem: (state, action: PayloadAction<SelectedBlockRule>) => {
      const newItem = action.payload;

      if (state.selectedItems.find((item) => item.id === newItem.id)) {
        state.selectedItems = state.selectedItems.filter(
          (item) => item.id !== newItem.id
        );
      } else {
        state.selectedItems = [
          ...state.selectedItems,
          { id: newItem.id, active: newItem.active },
        ];
      }
    },
    setSelectedItems: (state, action: PayloadAction<SelectedBlockRule[]>) => {
      state.selectedItems = action.payload;
    },
    addSelectedItems: (state, action: PayloadAction<SelectedBlockRule[]>) => {
      const data = action.payload.map((item) => ({
        id: item.id,
        active: item.active,
      }));

      const alreadySelectedIds = new Set(
        state.selectedItems.map((item) => item.id)
      );

      state.selectedItems = [
        ...state.selectedItems,
        ...data.filter((item) => !alreadySelectedIds.has(item.id)),
      ];
    },
    removeSelectedItems: (state, action: PayloadAction<UUID[]>) => {
      const idsToRemove = action.payload;

      state.selectedItems = state.selectedItems.filter(
        (current) => !idsToRemove.includes(current.id)
      );
    },
    clearSelectedItems: (state) => {
      state.selectedItems = [];
    },
    setAllItemsCount: (state, action: PayloadAction<number>) => {
      state.allItems = action.payload;
    },
  },
});

export const {
  setPaginatedList: setBlocklist,
  addItem: addBlocklistItem,
  setItem: setBlocklistItem,
  updateItem: updateBlocklistItem,
  removeList: removeBlocklist,
  setSelectedItems,
  removeSelectedItems,
  addSelectedItems,
  clearSelectedItems,
  toggleSelectedItem,
  setAllItemsCount,
} = slice.actions;

export const fetchBlocklist = createAction<{
  customerId: UUID;
  params: Partial<BlockRulesQueryParams>;
}>("fetchBlocklist");

export const fetchBlocklistAndRefreshAfterActions = createAction<{
  customerId: UUID;
  params: Partial<BlockRulesQueryParams>;
}>("fetchBlocklistAndRefreshAfterActions");

export const createBlocklistItem = createAction<{
  customerId: UUID;
  data: BlockRulePostBody;
}>("createBlocklistItem");

export const batchBlockItems = createAction<{
  customerId: UUID;
  ids: UUID[];
}>("batchBlockItems");
export const batchUnblockItems = createAction<{
  customerId: UUID;
  ids: UUID[];
}>("batchUnblockItems");

export const { selectPaginatedList: selectBlocklistData } = selectors;

export const selectSelectedItems = (state: RootState): SelectedBlockRule[] =>
  state.circuitBreakerBlocklist.selectedItems;

export const selectAllItemsCount = (state: RootState) =>
  state.circuitBreakerBlocklist.allItems;

export const BlocklistOperationPaths = {
  list: ["circuitBreaker", "blocklist", "list"],
  create: ["circuitBreaker", "blocklist", "create"],
  block: ["circuitBreaker", "blocklist", "block"],
  unblock: ["circuitBreaker", "blocklist", "unblock"],
};

export const circuitBreakerBlocklistReducer = slice.reducer;
