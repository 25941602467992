import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import * as Sentry from "@sentry/react";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";

import { authApi, portalApi } from "api/rtkApi";
import { ENVIRONMENT } from "constants/environment";
// eslint-disable-next-line no-restricted-imports
import { authPersistedReducer } from "modules/auth/store/auth.slice";
import { accountsReducer } from "modules/bank-accounts/store";
import { checkoutBrandingReducer } from "modules/checkout/store/slice";
import { circuitBreakerBlocklistReducer } from "modules/circuit-breaker/store/blocklist/blocklist.slice";
import { riskOverviewReducer } from "modules/circuit-breaker/store/riskOverview/riskOverview.slice";
import { sanitizeSensitiveDataFromPayload } from "modules/common/utils/sanitizeSensitiveDataFromPayload";
import { userManagementReducer } from "modules/user-management/store/users.slice";
import { userSettingsReducer } from "modules/user-settings/store";
import rootSaga from "sagas";

import { appSlice as appReducer } from "./appSlice";
import { valuesSlice as valuesReducer } from "./valuesSlice";

import type { Middleware } from "@reduxjs/toolkit";

const KEYS_TO_SANITIZE = ["accessToken", "refreshToken"] as const;

export const history = createBrowserHistory();

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: (state: RootState) => {
    if (state.auth.accessToken || state.auth.refreshToken) {
      return Object.assign({}, state, {
        auth: {
          ...state.auth,
          accessToken: "<redacted>",
          refreshToken: "<redacted>",
        },
      });
    }

    return state;
  },
  actionTransformer: (action) => {
    if (
      action.type.startsWith("__rtkq/") ||
      action.type.startsWith("portalApi/") ||
      action.type.startsWith("authApi/")
    ) {
      return null;
    }

    return sanitizeSensitiveDataFromPayload(action, KEYS_TO_SANITIZE);
  },
});

const appPersistConfig = {
  key: "app",
  storage,
  whitelist: ["environment", "globalFilteredCustomer"],
  blacklist: [],
};

const rootReducer = combineReducers({
  router: connectRouter(history),
  app: persistReducer(appPersistConfig, appReducer),
  auth: authPersistedReducer,
  checkoutBranding: checkoutBrandingReducer,
  accounts: accountsReducer,
  users: userManagementReducer,
  values: valuesReducer,
  circuitBreakerBlocklist: circuitBreakerBlocklistReducer,
  riskOverview: riskOverviewReducer,
  userSettings: userSettingsReducer,
  [authApi.reducerPath]: authApi.reducer,
  [portalApi.reducerPath]: portalApi.reducer,
});

export const getConfiguredStore = (
  middleware: Middleware[] = [],
  preloadedState = {}
) => {
  const sagaMiddleware = createSagaMiddleware();
  const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== ENVIRONMENT.PRODUCTION,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: {
          warnAfter: 100,
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      })
        .concat(portalApi.middleware)
        .concat(authApi.middleware)
        .concat(sagaMiddleware)
        // connected-react-router library is dead (https://github.com/supasate/connected-react-router/issues/613).
        // Although it didn't get official Redux 5.0 support everything seems to work fine.
        // The only issue is that TypeScript API is no longer compatible with RTK API, and we need to cast it to any.
        .concat(routerMiddleware(history) as any)
        .concat(middleware),
    enhancers: (getDefaultEnhancers) =>
      getDefaultEnhancers().concat(sentryReduxEnhancer),
    preloadedState,
  });

  sagaMiddleware.run(rootSaga);
  setupListeners(store.dispatch);

  return store;
};

export const persistor = (store: RootStore) =>
  persistStore(store, {}, () => {
    const state = store.getState();
    const userId = state.auth.userId;
    const userEmail = state.auth.username;

    if (userId) {
      Sentry.setUser({
        id: userId,
        email: userEmail ?? "",
      });
    } else {
      Sentry.setUser(null);
    }
  });

export type RootStore = ReturnType<typeof getConfiguredStore>;
export type RootState = ReturnType<RootStore["getState"]>;
