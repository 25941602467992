import { useEffect } from "react";

export function useEvent(
  name: string,
  handler: VoidFunction,
  target: HTMLElement | Window = window
) {
  useEffect(() => {
    target.addEventListener(name, handler);

    return () => {
      target.removeEventListener(name, handler);
    };
  }, [target, name, handler]);
}
