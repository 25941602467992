import type { Currency } from "models/Currency";
import type { CurrencyCode, SupportedCurrencyCode } from "models/CurrencyCode";

export const CURRENCY: Record<CurrencyCode, CurrencyCode> = {
  EUR: "EUR",
  AED: "AED",
  AFN: "AFN",
  ALL: "ALL",
  AMD: "AMD",
  ANG: "ANG",
  AOA: "AOA",
  ARS: "ARS",
  AUD: "AUD",
  AWG: "AWG",
  AZN: "AZN",
  BAM: "BAM",
  BBD: "BBD",
  BDT: "BDT",
  BGN: "BGN",
  BHD: "BHD",
  BIF: "BIF",
  BMD: "BMD",
  BND: "BND",
  BOB: "BOB",
  BOV: "BOV",
  BRL: "BRL",
  BSD: "BSD",
  BTN: "BTN",
  BWP: "BWP",
  BYN: "BYN",
  BZD: "BZD",
  CAD: "CAD",
  CDF: "CDF",
  CHE: "CHE",
  CHF: "CHF",
  CHW: "CHW",
  CLF: "CLF",
  CLP: "CLP",
  CNY: "CNY",
  COP: "COP",
  COU: "COU",
  CRC: "CRC",
  CUC: "CUC",
  CUP: "CUP",
  CVE: "CVE",
  CZK: "CZK",
  DJF: "DJF",
  DKK: "DKK",
  DOP: "DOP",
  DZD: "DZD",
  EGP: "EGP",
  ERN: "ERN",
  ETB: "ETB",
  FJD: "FJD",
  FKP: "FKP",
  GBP: "GBP",
  GEL: "GEL",
  GHS: "GHS",
  GIP: "GIP",
  GMD: "GMD",
  GNF: "GNF",
  GTQ: "GTQ",
  GYD: "GYD",
  HKD: "HKD",
  HNL: "HNL",
  HRK: "HRK",
  HTG: "HTG",
  HUF: "HUF",
  IDR: "IDR",
  ILS: "ILS",
  INR: "INR",
  IQD: "IQD",
  IRR: "IRR",
  ISK: "ISK",
  JMD: "JMD",
  JOD: "JOD",
  JPY: "JPY",
  KES: "KES",
  KGS: "KGS",
  KHR: "KHR",
  KMF: "KMF",
  KPW: "KPW",
  KRW: "KRW",
  KWD: "KWD",
  KYD: "KYD",
  KZT: "KZT",
  LAK: "LAK",
  LBP: "LBP",
  LKR: "LKR",
  LRD: "LRD",
  LSL: "LSL",
  LYD: "LYD",
  MAD: "MAD",
  MDL: "MDL",
  MGA: "MGA",
  MKD: "MKD",
  MMK: "MMK",
  MNT: "MNT",
  MOP: "MOP",
  MRO: "MRO",
  MRU: "MRU",
  MUR: "MUR",
  MVR: "MVR",
  MWK: "MWK",
  MXN: "MXN",
  MXV: "MXV",
  MYR: "MYR",
  MZN: "MZN",
  NAD: "NAD",
  NGN: "NGN",
  NIO: "NIO",
  NOK: "NOK",
  NPR: "NPR",
  NZD: "NZD",
  OMR: "OMR",
  PAB: "PAB",
  PEN: "PEN",
  PGK: "PGK",
  PHP: "PHP",
  PKR: "PKR",
  PLN: "PLN",
  PYG: "PYG",
  QAR: "QAR",
  RON: "RON",
  RSD: "RSD",
  RUB: "RUB",
  RWF: "RWF",
  SAR: "SAR",
  SBD: "SBD",
  SCR: "SCR",
  SDG: "SDG",
  SEK: "SEK",
  SGD: "SGD",
  SHP: "SHP",
  SLL: "SLL",
  SOS: "SOS",
  SRD: "SRD",
  SSP: "SSP",
  STD: "STD",
  STN: "STN",
  SVC: "SVC",
  SYP: "SYP",
  SZL: "SZL",
  THB: "THB",
  TJS: "TJS",
  TMT: "TMT",
  TND: "TND",
  TOP: "TOP",
  TRY: "TRY",
  TTD: "TTD",
  TWD: "TWD",
  TZS: "TZS",
  UAH: "UAH",
  UGX: "UGX",
  USD: "USD",
  USN: "USN",
  UYI: "UYI",
  UYU: "UYU",
  UYW: "UYW",
  UZS: "UZS",
  VEF: "VEF",
  VES: "VES",
  VND: "VND",
  VUV: "VUV",
  WST: "WST",
  XAF: "XAF",
  XAG: "XAG",
  XAU: "XAU",
  XBA: "XBA",
  XBB: "XBB",
  XBC: "XBC",
  XBD: "XBD",
  XCD: "XCD",
  XDR: "XDR",
  XEU: "XEU",
  XOF: "XOF",
  XPD: "XPD",
  XPF: "XPF",
  XPT: "XPT",
  XSU: "XSU",
  XUA: "XUA",
  XXX: "XXX",
  YER: "YER",
  ZAR: "ZAR",
  ZMW: "ZMW",
  ZWL: "ZWL",
};

export const currenciesMap: ReadonlyMap<Currency["id"], Currency> = new Map([
  [CURRENCY.EUR, { id: CURRENCY.EUR, decimal: 2 }],
  [CURRENCY.AED, { id: CURRENCY.AED, decimal: 2 }],
  [CURRENCY.AFN, { id: CURRENCY.AFN, decimal: 2 }],
  [CURRENCY.ALL, { id: CURRENCY.ALL, decimal: 2 }],
  [CURRENCY.AMD, { id: CURRENCY.AMD, decimal: 2 }],
  [CURRENCY.ANG, { id: CURRENCY.ANG, decimal: 2 }],
  [CURRENCY.AOA, { id: CURRENCY.AOA, decimal: 2 }],
  [CURRENCY.ARS, { id: CURRENCY.ARS, decimal: 2 }],
  [CURRENCY.AUD, { id: CURRENCY.AUD, decimal: 2 }],
  [CURRENCY.AWG, { id: CURRENCY.AWG, decimal: 2 }],
  [CURRENCY.AZN, { id: CURRENCY.AZN, decimal: 2 }],
  [CURRENCY.BAM, { id: CURRENCY.BAM, decimal: 2 }],
  [CURRENCY.BBD, { id: CURRENCY.BBD, decimal: 2 }],
  [CURRENCY.BDT, { id: CURRENCY.BDT, decimal: 2 }],
  [CURRENCY.BGN, { id: CURRENCY.BGN, decimal: 2 }],
  [CURRENCY.BHD, { id: CURRENCY.BHD, decimal: 3 }],
  [CURRENCY.BIF, { id: CURRENCY.BIF, decimal: 0 }],
  [CURRENCY.BMD, { id: CURRENCY.BMD, decimal: 2 }],
  [CURRENCY.BND, { id: CURRENCY.BND, decimal: 2 }],
  [CURRENCY.BOB, { id: CURRENCY.BOB, decimal: 2 }],
  [CURRENCY.BOV, { id: CURRENCY.BOV, decimal: 2 }],
  [CURRENCY.BRL, { id: CURRENCY.BRL, decimal: 2 }],
  [CURRENCY.BSD, { id: CURRENCY.BSD, decimal: 2 }],
  [CURRENCY.BTN, { id: CURRENCY.BTN, decimal: 2 }],
  [CURRENCY.BWP, { id: CURRENCY.BWP, decimal: 2 }],
  [CURRENCY.BYN, { id: CURRENCY.BYN, decimal: 2 }],
  [CURRENCY.BZD, { id: CURRENCY.BZD, decimal: 2 }],
  [CURRENCY.CAD, { id: CURRENCY.CAD, decimal: 2 }],
  [CURRENCY.CDF, { id: CURRENCY.CDF, decimal: 2 }],
  [CURRENCY.CHE, { id: CURRENCY.CHE, decimal: 2 }],
  [CURRENCY.CHF, { id: CURRENCY.CHF, decimal: 2 }],
  [CURRENCY.CHW, { id: CURRENCY.CHW, decimal: 2 }],
  [CURRENCY.CLF, { id: CURRENCY.CLF, decimal: 4 }],
  [CURRENCY.CLP, { id: CURRENCY.CLP, decimal: 0 }],
  [CURRENCY.CNY, { id: CURRENCY.CNY, decimal: 2 }],
  [CURRENCY.COP, { id: CURRENCY.COP, decimal: 2 }],
  [CURRENCY.COU, { id: CURRENCY.COU, decimal: 2 }],
  [CURRENCY.CRC, { id: CURRENCY.CRC, decimal: 2 }],
  [CURRENCY.CUC, { id: CURRENCY.CUC, decimal: 2 }],
  [CURRENCY.CUP, { id: CURRENCY.CUP, decimal: 2 }],
  [CURRENCY.CVE, { id: CURRENCY.CVE, decimal: 2 }],
  [CURRENCY.CZK, { id: CURRENCY.CZK, decimal: 2 }],
  [CURRENCY.DJF, { id: CURRENCY.DJF, decimal: 0 }],
  [CURRENCY.DKK, { id: CURRENCY.DKK, decimal: 2 }],
  [CURRENCY.DOP, { id: CURRENCY.DOP, decimal: 2 }],
  [CURRENCY.DZD, { id: CURRENCY.DZD, decimal: 2 }],
  [CURRENCY.EGP, { id: CURRENCY.EGP, decimal: 2 }],
  [CURRENCY.ERN, { id: CURRENCY.ERN, decimal: 2 }],
  [CURRENCY.ETB, { id: CURRENCY.ETB, decimal: 2 }],
  [CURRENCY.FJD, { id: CURRENCY.FJD, decimal: 2 }],
  [CURRENCY.FKP, { id: CURRENCY.FKP, decimal: 2 }],
  [CURRENCY.GBP, { id: CURRENCY.GBP, decimal: 2 }],
  [CURRENCY.GEL, { id: CURRENCY.GEL, decimal: 2 }],
  [CURRENCY.GHS, { id: CURRENCY.GHS, decimal: 2 }],
  [CURRENCY.GIP, { id: CURRENCY.GIP, decimal: 2 }],
  [CURRENCY.GMD, { id: CURRENCY.GMD, decimal: 2 }],
  [CURRENCY.GNF, { id: CURRENCY.GNF, decimal: 0 }],
  [CURRENCY.GTQ, { id: CURRENCY.GTQ, decimal: 2 }],
  [CURRENCY.GYD, { id: CURRENCY.GYD, decimal: 2 }],
  [CURRENCY.HKD, { id: CURRENCY.HKD, decimal: 2 }],
  [CURRENCY.HNL, { id: CURRENCY.HNL, decimal: 2 }],
  [CURRENCY.HRK, { id: CURRENCY.HRK, decimal: 2 }],
  [CURRENCY.HTG, { id: CURRENCY.HTG, decimal: 2 }],
  [CURRENCY.HUF, { id: CURRENCY.HUF, decimal: 2 }],
  [CURRENCY.IDR, { id: CURRENCY.IDR, decimal: 2 }],
  [CURRENCY.ILS, { id: CURRENCY.ILS, decimal: 2 }],
  [CURRENCY.INR, { id: CURRENCY.INR, decimal: 2 }],
  [CURRENCY.IQD, { id: CURRENCY.IQD, decimal: 3 }],
  [CURRENCY.IRR, { id: CURRENCY.IRR, decimal: 2 }],
  [CURRENCY.ISK, { id: CURRENCY.ISK, decimal: 0 }],
  [CURRENCY.JMD, { id: CURRENCY.JMD, decimal: 2 }],
  [CURRENCY.JOD, { id: CURRENCY.JOD, decimal: 3 }],
  [CURRENCY.JPY, { id: CURRENCY.JPY, decimal: 0 }],
  [CURRENCY.KES, { id: CURRENCY.KES, decimal: 2 }],
  [CURRENCY.KGS, { id: CURRENCY.KGS, decimal: 2 }],
  [CURRENCY.KHR, { id: CURRENCY.KHR, decimal: 2 }],
  [CURRENCY.KMF, { id: CURRENCY.KMF, decimal: 0 }],
  [CURRENCY.KPW, { id: CURRENCY.KPW, decimal: 2 }],
  [CURRENCY.KRW, { id: CURRENCY.KRW, decimal: 0 }],
  [CURRENCY.KWD, { id: CURRENCY.KWD, decimal: 3 }],
  [CURRENCY.KYD, { id: CURRENCY.KYD, decimal: 2 }],
  [CURRENCY.KZT, { id: CURRENCY.KZT, decimal: 2 }],
  [CURRENCY.LAK, { id: CURRENCY.LAK, decimal: 2 }],
  [CURRENCY.LBP, { id: CURRENCY.LBP, decimal: 2 }],
  [CURRENCY.LKR, { id: CURRENCY.LKR, decimal: 2 }],
  [CURRENCY.LRD, { id: CURRENCY.LRD, decimal: 2 }],
  [CURRENCY.LSL, { id: CURRENCY.LSL, decimal: 2 }],
  [CURRENCY.LYD, { id: CURRENCY.LYD, decimal: 3 }],
  [CURRENCY.MAD, { id: CURRENCY.MAD, decimal: 2 }],
  [CURRENCY.MDL, { id: CURRENCY.MDL, decimal: 2 }],
  [CURRENCY.MGA, { id: CURRENCY.MGA, decimal: 2 }],
  [CURRENCY.MKD, { id: CURRENCY.MKD, decimal: 2 }],
  [CURRENCY.MMK, { id: CURRENCY.MMK, decimal: 2 }],
  [CURRENCY.MNT, { id: CURRENCY.MNT, decimal: 2 }],
  [CURRENCY.MOP, { id: CURRENCY.MOP, decimal: 2 }],
  [CURRENCY.MRO, { id: CURRENCY.MRO, decimal: 2 }],
  [CURRENCY.MRU, { id: CURRENCY.MRU, decimal: 2 }],
  [CURRENCY.MUR, { id: CURRENCY.MUR, decimal: 2 }],
  [CURRENCY.MVR, { id: CURRENCY.MVR, decimal: 2 }],
  [CURRENCY.MWK, { id: CURRENCY.MWK, decimal: 2 }],
  [CURRENCY.MXN, { id: CURRENCY.MXN, decimal: 2 }],
  [CURRENCY.MXV, { id: CURRENCY.MXV, decimal: 2 }],
  [CURRENCY.MYR, { id: CURRENCY.MYR, decimal: 2 }],
  [CURRENCY.MZN, { id: CURRENCY.MZN, decimal: 2 }],
  [CURRENCY.NAD, { id: CURRENCY.NAD, decimal: 2 }],
  [CURRENCY.NGN, { id: CURRENCY.NGN, decimal: 2 }],
  [CURRENCY.NIO, { id: CURRENCY.NIO, decimal: 2 }],
  [CURRENCY.NOK, { id: CURRENCY.NOK, decimal: 2 }],
  [CURRENCY.NPR, { id: CURRENCY.NPR, decimal: 2 }],
  [CURRENCY.NZD, { id: CURRENCY.NZD, decimal: 2 }],
  [CURRENCY.OMR, { id: CURRENCY.OMR, decimal: 3 }],
  [CURRENCY.PAB, { id: CURRENCY.PAB, decimal: 2 }],
  [CURRENCY.PEN, { id: CURRENCY.PEN, decimal: 2 }],
  [CURRENCY.PGK, { id: CURRENCY.PGK, decimal: 2 }],
  [CURRENCY.PHP, { id: CURRENCY.PHP, decimal: 2 }],
  [CURRENCY.PKR, { id: CURRENCY.PKR, decimal: 2 }],
  [CURRENCY.PLN, { id: CURRENCY.PLN, decimal: 2 }],
  [CURRENCY.PYG, { id: CURRENCY.PYG, decimal: 0 }],
  [CURRENCY.QAR, { id: CURRENCY.QAR, decimal: 2 }],
  [CURRENCY.RON, { id: CURRENCY.RON, decimal: 2 }],
  [CURRENCY.RSD, { id: CURRENCY.RSD, decimal: 2 }],
  [CURRENCY.RUB, { id: CURRENCY.RUB, decimal: 2 }],
  [CURRENCY.RWF, { id: CURRENCY.RWF, decimal: 0 }],
  [CURRENCY.SAR, { id: CURRENCY.SAR, decimal: 2 }],
  [CURRENCY.SBD, { id: CURRENCY.SBD, decimal: 2 }],
  [CURRENCY.SCR, { id: CURRENCY.SCR, decimal: 2 }],
  [CURRENCY.SDG, { id: CURRENCY.SDG, decimal: 2 }],
  [CURRENCY.SEK, { id: CURRENCY.SEK, decimal: 2 }],
  [CURRENCY.SGD, { id: CURRENCY.SGD, decimal: 2 }],
  [CURRENCY.SHP, { id: CURRENCY.SHP, decimal: 2 }],
  [CURRENCY.SLL, { id: CURRENCY.SLL, decimal: 2 }],
  [CURRENCY.SOS, { id: CURRENCY.SOS, decimal: 2 }],
  [CURRENCY.SRD, { id: CURRENCY.SRD, decimal: 2 }],
  [CURRENCY.SSP, { id: CURRENCY.SSP, decimal: 2 }],
  [CURRENCY.STD, { id: CURRENCY.STD, decimal: 2 }],
  [CURRENCY.STN, { id: CURRENCY.STN, decimal: 2 }],
  [CURRENCY.SVC, { id: CURRENCY.SVC, decimal: 2 }],
  [CURRENCY.SYP, { id: CURRENCY.SYP, decimal: 2 }],
  [CURRENCY.SZL, { id: CURRENCY.SZL, decimal: 2 }],
  [CURRENCY.THB, { id: CURRENCY.THB, decimal: 2 }],
  [CURRENCY.TJS, { id: CURRENCY.TJS, decimal: 2 }],
  [CURRENCY.TMT, { id: CURRENCY.TMT, decimal: 2 }],
  [CURRENCY.TND, { id: CURRENCY.TND, decimal: 3 }],
  [CURRENCY.TOP, { id: CURRENCY.TOP, decimal: 2 }],
  [CURRENCY.TRY, { id: CURRENCY.TRY, decimal: 2 }],
  [CURRENCY.TTD, { id: CURRENCY.TTD, decimal: 2 }],
  [CURRENCY.TWD, { id: CURRENCY.TWD, decimal: 2 }],
  [CURRENCY.TZS, { id: CURRENCY.TZS, decimal: 2 }],
  [CURRENCY.UAH, { id: CURRENCY.UAH, decimal: 2 }],
  [CURRENCY.UGX, { id: CURRENCY.UGX, decimal: 0 }],
  [CURRENCY.USD, { id: CURRENCY.USD, decimal: 2 }],
  [CURRENCY.USN, { id: CURRENCY.USN, decimal: 2 }],
  [CURRENCY.UYI, { id: CURRENCY.UYI, decimal: 0 }],
  [CURRENCY.UYU, { id: CURRENCY.UYU, decimal: 2 }],
  [CURRENCY.UYW, { id: CURRENCY.UYW, decimal: 4 }],
  [CURRENCY.UZS, { id: CURRENCY.UZS, decimal: 2 }],
  [CURRENCY.VEF, { id: CURRENCY.VEF, decimal: 2 }],
  [CURRENCY.VES, { id: CURRENCY.VES, decimal: 2 }],
  [CURRENCY.VND, { id: CURRENCY.VND, decimal: 0 }],
  [CURRENCY.VUV, { id: CURRENCY.VUV, decimal: 0 }],
  [CURRENCY.WST, { id: CURRENCY.WST, decimal: 2 }],
  [CURRENCY.XAF, { id: CURRENCY.XAF, decimal: 0 }],
  [CURRENCY.XAG, { id: CURRENCY.XAG, decimal: 5 }],
  [CURRENCY.XAU, { id: CURRENCY.XAU, decimal: 5 }],
  [CURRENCY.XBA, { id: CURRENCY.XBA, decimal: 5 }],
  [CURRENCY.XBB, { id: CURRENCY.XBB, decimal: 5 }],
  [CURRENCY.XBC, { id: CURRENCY.XBC, decimal: 5 }],
  [CURRENCY.XBD, { id: CURRENCY.XBD, decimal: 5 }],
  [CURRENCY.XCD, { id: CURRENCY.XCD, decimal: 2 }],
  [CURRENCY.XDR, { id: CURRENCY.XDR, decimal: 5 }],
  [CURRENCY.XEU, { id: CURRENCY.XEU, decimal: 2 }],
  [CURRENCY.XOF, { id: CURRENCY.XOF, decimal: 0 }],
  [CURRENCY.XPD, { id: CURRENCY.XPD, decimal: 5 }],
  [CURRENCY.XPF, { id: CURRENCY.XPF, decimal: 0 }],
  [CURRENCY.XPT, { id: CURRENCY.XPT, decimal: 5 }],
  [CURRENCY.XSU, { id: CURRENCY.XSU, decimal: 0 }],
  [CURRENCY.XUA, { id: CURRENCY.XUA, decimal: 0 }],
  [CURRENCY.XXX, { id: CURRENCY.XXX, decimal: 5 }],
  [CURRENCY.YER, { id: CURRENCY.YER, decimal: 2 }],
  [CURRENCY.ZAR, { id: CURRENCY.ZAR, decimal: 2 }],
  [CURRENCY.ZMW, { id: CURRENCY.ZMW, decimal: 2 }],
  [CURRENCY.ZWL, { id: CURRENCY.ZWL, decimal: 2 }],
]);

export const SUPPORTED_CURRENCIES: Record<
  SupportedCurrencyCode,
  SupportedCurrencyCode
> = {
  EUR: "EUR",
  BGN: "BGN",
  CZK: "CZK",
  DKK: "DKK",
  HUF: "HUF",
  PLN: "PLN",
  RON: "RON",
  SEK: "SEK",
  GBP: "GBP",
  AUD: "AUD",
  BRL: "BRL",
  USD: "USD",
};

export const SUPPORTED_CURRENCIES_VALUES = Object.values(SUPPORTED_CURRENCIES);
