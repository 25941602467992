/*eslint perfectionist/sort-objects: "error"*/

export const enGb = {
  account: "Account",
  accountBalance: "Account balance",
  accountDetails: "Account details",
  accountNotFound: "Account not found",
  accountNumber: "Account number",
  accountOwner: "Account owner",
  accounts: "Accounts",
  accountsHaveBeenSettled: "Accounts have been settled",
  accountType: "Account type",
  active: "Active",
  address: "Address",
  amount: "Amount ({{currency}})",
  areYouSureYouWantToSettleAllAccounts:
    "Are you sure you want to settle all accounts?",
  areYouSureYouWantToSettleThisAccount:
    "Are you sure you want to settle this account?",
  availableFunds: "Available funds",
  backToAllAccounts: "Back to all accounts",
  balanceAfter: "Balance after",
  balanceCurrency: "Balance ({{currency}})",
  closedLoopPayout: "Closed loop payout",
  completed: "Completed",
  connected: "Connected",
  connectMatching: "Connect Matching",
  country: "Country",
  credit: "Credit",
  dateWhenTheTransactionWasCreated: "Date when the transaction was created",
  dateWhenTheTransactionWasProcessed: "Date when the transaction was processed",
  deactivated: "Deactivated",
  debit: "Debit",
  email: "Email",
  failed: "Failed",
  givenAccountCouldNotBeFound: "Given account could not be found",
  iban: "IBAN",
  ignored: "Ignored",
  inactive: "Inactive",
  inPayout: "In payout",
  inReturn: "In return",
  name: "Name",
  noAccounts: "No accounts",
  noAccountsToSettle: "No accounts to settle",
  noAmountToSettle: "No amount to settle",
  noTransactions: "No transactions",
  openLoopPayout: "Open loop payout",
  payment: "Payment",
  paymentScheme: "Payment scheme",
  pending: "Pending",
  processing: "Processing",
  recalled: "Recalled",
  reference: "Reference",
  refreshAllAccounts: "Refresh all accounts",
  refund: "Refund",
  rejected: "Rejected",
  retry: "Retry",
  return: "Return",
  returned: "Returned",
  returnedManually: "Returned manually",
  reversal: "Reversal",
  selectCustomer: "Select customer",
  sender: "Sender",
  settleAccount: "Settle account",
  settleAllAccounts: "Settle all accounts",
  settlement: "Settlement",
  settlementAmountNotProvided: "Settlement amount not provided",
  settlementBalance: "Settlement balance",
  settlementOfAllAccounts: "Settlement of all accounts",
  settlementOfThisAccount: "Settlement of this account",
  sortCode: "Sort code",
  swiftCodeBic: "Swift code (BIC)",
  toViewAccountsSelectCustomer:
    "To view accounts, please select a customer in the select customer menu in the upper left corner.",
  transactionAmount: "Transaction amount",
  transactionDate: "Transaction date",
  transactionDetails: "Transaction details",
  transactions: "Transactions",
  uniqueReference: "Unique reference",
  unknownType: "Unknown Type",
  valueDate: "Value date",
  viewDetails: "View details",
  virtualIBANs: "Virtual IBANs",
  volt: "Volt",
  voltId: "Volt ID",
  voltPayoutId: "Volt payout ID",
  voltRefundId: "Volt refund ID",
  voltSettlementId: "Volt settlement ID",
  waiting: "Waiting",
  weHaveSettledYourAccount: "We have settled your account",
  withdrawal: "Withdrawal",
  youDontHaveAnyAccountsYet: "You don't have any accounts yet.",
  youDontHaveAnyTransactionsYet: "You don't have any transactions yet.",
};
