import { COMMON_TRANSLATION_KEYS } from "constants/commonTranslationsKeys";
import { lazyRetry } from "utils/lazyRetry";

import type { Route } from "routes/RouteModel";

const UserSettings = lazyRetry(() => import("../pages/index"), "UserSettings");

export const userSettingsRoutes: Route = {
  title: [COMMON_TRANSLATION_KEYS.userSettings],
  path: "/settings",
  hidden: true,
  forceDisplayInHeader: true,
  component: UserSettings,
};
