import { Pill } from "@volt_developers/components";

import { ENVIRONMENT } from "constants/environment";

import { useEnvironmentName } from "../../hooks/useEnvironmentName";

import type { PillProps } from "@volt_developers/components";

export function EnvironmentPill({ sx, ...props }: Omit<PillProps, "variant">) {
  const envName = useEnvironmentName();
  const variant = envName === ENVIRONMENT.SANDBOX ? "progress" : "default";

  return (
    <Pill
      {...props}
      sx={{ "& > *": { textTransform: "capitalize" }, ...sx }}
      variant={variant}
    >
      {envName}
    </Pill>
  );
}
