import { push } from "connected-react-router";
import i18n from "i18next";
import { put, select, takeEvery, takeLatest } from "redux-saga/effects";

import { handleApiErrors } from "api/helpers/handleApiErrors";
import { toastForApiErrors } from "api/helpers/toastForApiErrors";
import { portalApi, TAGS } from "api/rtkApi";
import { showMessageToast } from "components/toasts/MessageToast";
import { COMMON_TRANSLATION_KEYS } from "constants/commonTranslationsKeys";
import { LOCAL_STORAGE_KEYS } from "constants/LocalStorageItems";
import { callApi } from "sagas/helpers/api";
import {
  selectOperationState,
  setError,
  setSuccess,
  startLoading,
} from "store/appSlice";
import { LocalStorage } from "utils/LocalStorage";
import { trimObjectProperties } from "utils/trimObjectProperties";

import * as UsersApi from "../api/Users";
import { USERS_URL } from "../constants";
import { TRANSLATION_KEYS } from "../constants/TranslationKeys";
import { USER_TOASTS, USER_TOASTS_IDS } from "../constants/UserToasts";
import {
  createUser,
  fetchUsers,
  sendProductInterest,
  setList,
  updateUser,
  userOperationPaths,
} from "./users.slice";

import type { PayloadAction } from "@reduxjs/toolkit";

import type { UUID } from "utils";
import type { ObjectWithId } from "utils/ObjectWithId";

import type { UserForm } from "../models/UserForm";

export interface CustomerIdPayload {
  customerId: UUID;
}

export type UpdateUserPayload = ObjectWithId & {
  data: any;
};

function* fetchUsersSaga({ payload }: PayloadAction<CustomerIdPayload>) {
  const { customerId } = payload;
  const operationPath = userOperationPaths().fetchUsers;

  yield put(startLoading(operationPath));

  try {
    // @ts-ignore noImplicitAny
    const data = yield callApi(UsersApi.getUsers, { customerId });

    yield put(setList(data));
    yield put(setSuccess(operationPath));
  } catch (error) {
    yield put(
      setError({
        path: operationPath,
        error,
      })
    );
  }
}

function* createUserSaga({ payload }: PayloadAction<{ data: UserForm }>) {
  const userData = prepareUserData(payload.data);
  const operationPath = userOperationPaths().createUser;

  yield put(startLoading(operationPath));

  try {
    const userId: UUID = yield callApi(UsersApi.postUser, userData);

    yield put(portalApi.util.invalidateTags([TAGS.USERS]));
    yield put(setSuccess(operationPath));
    yield put(push(`${USERS_URL}/${userId}`));
    showMessageToast(
      USER_TOASTS.createUserSuccess(
        i18n.t(`users:${TRANSLATION_KEYS.theUserHasBeenCreated}`, {
          email: userData.email,
        })
      )
    );
  } catch (error) {
    const errors = handleApiErrors(error);

    if (errors) {
      toastForApiErrors(errors, USER_TOASTS_IDS.createFailure);
    } else {
      showMessageToast(
        USER_TOASTS.createUserFailure(
          i18n.t(`common:${COMMON_TRANSLATION_KEYS.sorrySomethingWentWrong}`)
        )
      );
    }

    yield put(
      setError({
        path: operationPath,
        error,
      })
    );
  }
}

function* updateUserSaga({ payload }: PayloadAction<UpdateUserPayload>) {
  const { data, id } = payload;
  const userData = prepareUserData(data);
  const operationPath = userOperationPaths(id).updateUser;

  yield put(startLoading(operationPath));

  try {
    const userId: UUID = yield callApi(UsersApi.patchUser, id, userData);

    yield put(portalApi.util.invalidateTags([{ type: TAGS.USER, id }]));
    yield put(portalApi.util.invalidateTags([TAGS.USERS]));
    yield put(setSuccess(operationPath));
    yield put(push(`${USERS_URL}/${userId}`));
    showMessageToast(
      USER_TOASTS.editUserSuccess(
        i18n.t(`users:${TRANSLATION_KEYS.theUserHasBeenModified}`)
      )
    );
  } catch (error) {
    const errors = handleApiErrors(error);

    if (errors) {
      toastForApiErrors(errors, USER_TOASTS_IDS.editFailure);
    } else {
      showMessageToast(
        USER_TOASTS.editUserFailure(
          i18n.t(`common:${COMMON_TRANSLATION_KEYS.sorrySomethingWentWrong}`)
        )
      );
    }

    yield put(
      setError({
        path: operationPath,
        error,
      })
    );
  }
}

function* sendProductInterestSaga({
  payload,
}: PayloadAction<{ productName: string; userId: UUID }>) {
  const operationPath = userOperationPaths().productInterest;
  const currentState: { type: string } | undefined = yield select(
    selectOperationState(operationPath)
  );

  if (currentState?.type === "LOADING_STATE") {
    showMessageToast({
      bodyContent: "Processing of previous request in progress",
      variant: "info",
    });

    return;
  }

  yield put(startLoading(operationPath));

  try {
    yield callApi(UsersApi.sendProductInterest, {
      productName: payload.productName,
    });
    yield put(setSuccess(operationPath));
    const currentSavedItems = LocalStorage.getItem<{
      [key: string]: string[];
    }>(LOCAL_STORAGE_KEYS.requestedFeatures);

    LocalStorage.saveItem(LOCAL_STORAGE_KEYS.requestedFeatures, {
      ...currentSavedItems,
      [payload.userId]: [
        ...(currentSavedItems[payload.userId] ?? []),
        payload.productName,
      ],
    });
    showMessageToast({
      bodyContent: i18n.t(
        "Thanks for your interest in {{productName}}. One of the team will contact you soon.",
        { productName: payload.productName }
      ),
      variant: "info",
    });
  } catch (error) {
    yield put(
      setError({
        path: operationPath,
        error,
      })
    );
    showMessageToast({
      bodyContent:
        "Request could not be processed. Please contact support@volt.io",
      variant: "failure",
    });
  }
}

export function* rootSaga() {
  yield takeLatest(fetchUsers.type, fetchUsersSaga);
  yield takeEvery(createUser.type, createUserSaga);
  yield takeEvery(updateUser.type, updateUserSaga);
  yield takeEvery(sendProductInterest.type, sendProductInterestSaga);
}

function prepareUserData(data: UserForm) {
  const userData = trimObjectProperties(data);

  if (userData.voltEmployee) {
    userData.customer = null;
  }

  return userData;
}
