/*eslint perfectionist/sort-objects: "error"*/

export const enGb = {
  ACTIVE: "Active",
  activeItemsWillBeChecked:
    "Active items will be checked during payment processing, unless you change the status to Inactive.",
  add: "Add",
  addANewItem: "Add a new item",
  addFilter: "Add filter",
  addItem: "Add Item",
  addNewAllowlistItem: "Add new allowlist item",
  addNewBlocklistItem: "Add new blocklist item",
  addNewItem_plural: "Add {{count}} new items",
  addNewItems: "Add new items",
  addRule: "Add Rule",
  addToAllowlist: "Add to allowlist",
  allowItemsHaveBeenCreated: "Allow item has been created",
  allowItemsHaveBeenCreated_other: "Allow items have been created",
  allowlist: "Allowlist",
  allTransactionsThatMatchACriteriaAddedToTheBlocklistWillBeBlocked:
    "All transactions that match a criteria added to the blocklist will be blocked",

  areYouSureThatYouWantToRemoveThisRule:
    "Are you sure that you want to remove this rule?",

  areYouSureYouWantToChangeStatus:
    "Are you sure you want to change the status of selected items?",
  bankBranch: "Bank branch",
  bankCountry: "Bank country",
  block: "Block",
  blockAgain: "Block again",
  blockedBy: "Blocked by",
  blockItemsCount: "Block ({{itemsCount}})",

  blockItemsHaveBeenCreated: "Block item has been created",

  blockItemsHaveBeenCreated_other: "Block items have been created",

  blocklist: "Blocklist",
  cancel: "Cancel",
  chooseAndEnterTheItemYouWantAllowed:
    "Choose and enter the item you want allowed",
  chooseAndEnterTheItemYouWantBlocked:
    "Choose and enter the item you want blocked",

  circuitBreaker: "Circuit Breaker",
  circuitBreakerIsDisabledForThisCustomer:
    "Circuit Breaker is disabled for this Customer.",
  circuitBreakerIsInactive: "Circuit Breaker is inactive",
  circuitBreakerIsOurFraudPreventionTool:
    "Circuit Breaker is our fraud prevention tool. If you want to get access, please contact Volt support.",
  circuitBreakerIsParticularlyPowerfulWhenWorkingTogetherWith:
    "Circuit Breaker is particularly powerful when working together with ",
  circuitBreakerWasNotActivatedGoToDetails:
    "Circuit Breaker was not activated for this customer. Go to customer details in order to enable the product.",
  circuitBreakerWasNotActivatedTalkToAdmin:
    "Circuit Breaker was not activated for this customer, do talk to your system administrator if you think there is an issue or our support team.",
  circuitBreakerWasNotActivatedTalkToSupport:
    "Circuit Breaker was not activated for this customer, do talk our support team if you think there is an issue.",
  confirmBlocking: "Confirm blocking",
  confirmUnblocking: "Confirm unblocking",
  createARiskRule: "Create a risk rule",
  createRulesScoreTutorial:
    "You create rules, and each has a certain score. Once a transaction is initiated, Circuit Breaker sums the score of all the rules triggered and, if they are 100 points or higher, blocks the transaction. Example:",
  currency: "Currency",
  date: "Date",
  deactivate: "Deactivate",
  documentId: "Document ID",
  emailAddress: "Email address",
  enterMoreThanOneCharacter: "Enter more than one character",
  exclude: "Exclude",
  failedToFetchUsers: "Failed to fetch users",
  fingerprint: "Fingerprint",
  goToAllowlist: "Go to Allowlist",
  goToBlocklist: "Go to Blocklist",
  goToCustomer: "Go to Customer",
  goToCustomers: "Go to Customers",
  howBlocklistWorks: "How blocklist works",
  howItWorks: "How it works?",
  iban: "IBAN",
  if: "If",
  in: "in",
  INACTIVE: "Inactive",
  inactiveItemsWillBeChecked:
    "Inactive items will not be checked during payment processing, unless you change the status back to Active.",
  include: "Include",
  interestSubmitted: "Interest submitted",
  ip: "IP",
  isGreaterThan: "is greater than",
  isLessThan: "is less than",
  isVoltsFraudDefenseSystemTailoredToBlockFraudstersOfTheNewOpenBankingEra:
    "Circuit Breaker is Volt's fraud defense system, tailored to block fraudsters of the new open banking era. You can read more about it in our <Link>official documentation</Link>.",
  items: "Items",
  itemsToAdd: "Items to add",
  limitRule: "Limit rule",
  limits: "Limits:",
  loading: "loading...",
  newItems: "New items",
  noOptions: "No options",
  noResults: "No results",
  nothingToShowYet: "Nothing to show yet.",
  numAllowlistItemsSelected: "{{count}} allowlist item selected",
  numAllowlistItemsSelected_plural: "{{count}} allowlist items selected",
  numberOfInitiatedPayments: "Number of initiated payments",
  numItemsHaveBeenActivated: "{{count}} item has been activated successfully",

  numItemsHaveBeenActivated_other:
    "{{count}} items have been activated successfully",
  numItemsHaveBeenDeactivated:
    "{{count}} item has been deactivated successfully",
  numItemsHaveBeenDeactivated_other:
    "{{count}} items have been deactivated successfully",

  numListItemsSelected: "{{count}} item selected",

  numListItemsSelected_plural: "{{count}} allowlist items selected",

  payerId: "Payer ID",
  paymentAmount: "Payment amount",
  pleaseProvideBankCountriesList:
    "Please provide values for bank countries limit",
  pleaseProvideValidValue: "Please, provide valid value",
  pleaseProvideValidValue_plural: "Please, provide valid values",
  remove: "Remove",
  retry: "Retry",
  returnToDashboard: "Return to dashboard",
  riskOverview: "Risk overview",
  riskRuleHasBeenActivated: "Risk Rule has been activated",
  riskRuleHasBeenDeactivated: "Risk Rule has been deactivated",
  riskRules: "Risk rules",
  rule: "Rule",
  score: "Score:",
  searchByValue: "Search by value",
  select: "Select",
  selectCustomer: "Select customer",
  selectCustomerToSeeMenu:
    "Select customer from the select customer menu to see Circuit Breaker menu.",
  setActive: "Set Active",
  setInactive: "Set Inactive",
  somethingWentWrong: "Something went wrong.",
  status: "Status",
  submitInterest: "Submit Interest",
  theRuleAdds10PointsTutorial:
    "This rule adds 10 points to the total score so that it won't block anything by itself, but if the same payer initiates 7 transactions or more, in conjunction with the following rule, the seventh transaction will be blocked",
  theScoreCannotBe0: "The score cannot be 0.",
  thresholdBelowNoTransactionWillBeBlocked:
    "The score of currently active rules sums to {{score}} what is below {{threshold}} threshold. No transaction will be blocked.",
  toViewAllowListSelectCustomer:
    "To view Allowlist, please select a customer in the select customer menu in the upper left corner.",
  toViewBlockListSelectCustomer:
    "To view Blocklist, please select a customer in the select customer menu in the upper left corner.",
  toViewRiskRulesSelectCustomer:
    "To view Risk rules, please select a customer in the select customer menu in the upper left corner.",
  unblock: "Unblock",
  unblockItemsCount: "Unblock ({{itemsCount}})",
  value: "Value",
  voltAdminUser: "Volt Admin user",
  volumeOfPayments: "Volume of payments",
  weWereNotAbleToProceedWithActivation:
    "We were not able to proceed with activation. Please, try again later.",
  weWereNotAbleToProceedWithDeactivation:
    "We were not able to proceed with deactivation. Please, try again later.",
  whenBothRulesAreTriggeredTheTotalScoreIs:
    "When both rules are triggered, the total score is <strong>10 + 90 = 100</strong>. Therefore, the payment is being blocked. Having too many fraudsters in a country or using a specific payment method? Don't worry! You can also add filters to your rule so it will only be applied to transactions matching that filter! Circuit Breaker is particularly powerful when working together with <Link>Volt Connect</Link>.",
  yesSetActive: "Yes, Set Active",
  yesSetInactive: "Yes, Set Inactive",
  youCanAlsoUnblockAnyKeyWheneverNeeded:
    "You can also unblock any key whenever needed",
  youCanOnlyAddUpTo: "You can only add up to {{maxAllowRules}} rules at a time",
  youDontHaveAnyAllowlistItemsGetStarted:
    "You don't have any allowlist items set up right now, let's get started.",
  youDontHaveAnyAllowlistItemsTalkToAdministrator:
    "You don't have any allowlist items set up right now, do talk to your system administrator if you think there is an issue or our support team.",
  youDontHaveAnyBlocklistItemsGetStarted:
    "You don't have any blocklist items set up right now, let's get started.",
  youDontHaveAnyBlocklistItemsTalkToAdministrator:
    "You don't have any blocklist items set up right now, do talk to your system administrator if you think there is an issue or our support team.",
  youDontHaveAnyRiskRulesCreatedYet:
    "You don't have any risk rules created yet.",
};
