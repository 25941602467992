import * as qs from "query-string";

import type { DefaultFilterConfiguration } from "modules/common/hooks/useGetUrlFilters";

export function getRouteWithPersistedFilterParams(
  url: string,
  key: string,
  filtersConfiguration: DefaultFilterConfiguration
) {
  const storageFilters = sessionStorage.getItem(key);
  const filtersFromConfig = Object.fromEntries(
    Object.entries(filtersConfiguration).map(([key, value]) => [
      key,
      value.defaultValue,
    ])
  );

  const filters = storageFilters
    ? JSON.parse(storageFilters)
    : filtersFromConfig;

  const parsedFilters = Object.fromEntries(
    Object.entries(filters).filter(([_, value]) =>
      Array.isArray(value) ? Boolean(value.length) : Boolean(value)
    )
  );

  const stringified = qs.stringify(parsedFilters, { arrayFormat: "comma" });

  const urlToReturn = stringified.length > 0 ? `${url}?${stringified}` : url;

  return urlToReturn;
}
