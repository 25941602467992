import * as Yup from "yup";

import { hexColor } from "utils/patterns";

declare module "yup" {
  export interface StringSchema {
    hexColor(): this;
  }
}

Yup.addMethod<Yup.StringSchema>(Yup.string, "hexColor", function () {
  return this.matches(hexColor, { excludeEmptyString: true });
});
