import { call, put, select } from "redux-saga/effects";

import {
  destroySession,
  forceUpdateToken,
  selectAccessToken,
} from "modules/auth/store/auth.slice";
import { selectEnvironment } from "store/appSlice";

import type { CallEffect, SagaReturnType } from "redux-saga/effects";

type CallOptions = {
  environment: any;
  headers: any;
};

export type ApiCallParameters<T extends any[]> = T extends [
  ...infer Head,
  CallOptions,
]
  ? Head
  : any[];

export function* callApiSaga<Fn extends (...args: any[]) => any>(
  fn: Fn,
  ...args: ApiCallParameters<Parameters<Fn>>
): Generator<any> {
  const accessToken = yield select(selectAccessToken);
  const environment = yield select(selectEnvironment);

  if (!accessToken) {
    yield put(destroySession());

    return;
  }

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  let response;
  try {
    const callArgs = [...args, { headers, environment }];

    response = yield call(fn, ...(callArgs as Parameters<Fn>));
  } catch (error) {
    if (error.code === 401) {
      console.info("Authentication error, signing out");
      yield put(forceUpdateToken());
    }

    console.error(error);
    throw error;
  }

  return response;
}

export function callApi<Fn extends (...args: any[]) => any>(
  fn: Fn,
  ...args: ApiCallParameters<Parameters<Fn>>
): CallEffect<SagaReturnType<Fn>> {
  return call(callApiSaga, fn, ...args);
}
