import { Skeleton, styled } from "@mui/material";

import { Card } from "modules/common/components/Card";

import type { Theme } from "@mui/material";
import type { SystemStyleObject } from "@mui/system";
import type { ComponentProps } from "react";

interface CardItemSkeletonProps {
  fullWidth?: boolean;
  height?: number;
  skeletonProps?: Partial<ComponentProps<typeof Skeleton>>;
  sx?: SystemStyleObject<Theme>;
  width?: number;
}

export function CardItemSkeleton({
  fullWidth,
  width = SKELETON_WIDTH,
  height = SKELETON_HEIGHT,
  sx,
  skeletonProps,
  ...other
}: CardItemSkeletonProps) {
  return (
    <Card.Item
      label={<Skeleton sx={{ width, height }} />}
      {...(fullWidth && { sm: 12 })}
      sx={sx}
      {...other}
    >
      <Wrapper>
        <Skeleton
          {...skeletonProps}
          sx={{ width: "100%", height, ...skeletonProps?.sx }}
        />
      </Wrapper>
    </Card.Item>
  );
}

const Wrapper = styled("div")(({ theme }) => ({
  margin: theme.spacing(1, 0),
}));

const SKELETON_WIDTH = 100;
const SKELETON_HEIGHT = 14;
