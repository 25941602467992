import { COMMON_TRANSLATION_KEYS } from "constants/commonTranslationsKeys";
import { ACTION_CREATE, ACTION_LIST } from "models/role/action";
import { SUBJECT_CIRCUIT_BREAKER } from "models/role/subject";
import { lazyRetry } from "utils/lazyRetry";

import { PAGE_URLS } from "../constants/PageUrls";

import type { Route } from "routes/RouteModel";

const Allowlist = lazyRetry(
  () => import("../pages/Allowlist"),
  "CircuitBreakerAllowlist"
);

const AllowlistCreate = lazyRetry(
  () => import("../pages/AllowlistCreate"),
  "CircuitBreakerAllowlistCreate"
);

export const allowlistRoute: Route = {
  component: Allowlist,
  title: [COMMON_TRANSLATION_KEYS.allowlist, COMMON_TRANSLATION_KEYS.list],
  path: PAGE_URLS.allowlist,
  can: {
    do: ACTION_LIST,
    on: SUBJECT_CIRCUIT_BREAKER,
  },
  children: [
    {
      title: [
        COMMON_TRANSLATION_KEYS.allowlist,
        COMMON_TRANSLATION_KEYS.newItems,
      ],
      path: PAGE_URLS.allowlistCreate,
      component: AllowlistCreate,
      can: {
        do: ACTION_CREATE,
        on: SUBJECT_CIRCUIT_BREAKER,
      },
    },
  ],
};
