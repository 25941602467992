import { QUICK_FILTERS_KEY } from "modules/common/constants/QuickFilters";
import { getPersistedFilterKey } from "modules/common/hooks/usePersistedFilters";

import type { DefaultFilterConfiguration } from "modules/common/hooks/useGetUrlFilters";

import type { ClientFilters } from "../models/Filters";

export const DEFAULT_FILTERS = {
  quickFilters: [],
  customerId: [],
} as const satisfies ClientFilters;

export const DEFAULT_FILTERS_CONFIGURATION = {
  quickFilters: {
    array: true,
    defaultValue: [],
  },
  customerId: {
    array: true,
    defaultValue: [],
  },
} as const satisfies DefaultFilterConfiguration<ClientFilters>;

export const FILTERS_PERSISTED_KEY = getPersistedFilterKey("applications");

export const FILTERS_KEYS = {
  CUSTOMER_ID: "customerId",
  QUICK_FILTERS: QUICK_FILTERS_KEY,
} as const;
