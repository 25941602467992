import type { Params } from "modules/common/hooks/useQueryString";

export function getActiveFiltersCount(
  filters: Params,
  filtersToOmitInCount: string[] = []
) {
  return Object.entries(filters).reduce((acc, [key, value]) => {
    const needToBeOmitted =
      filtersToOmitInCount.includes(key) || value === null || value === false;

    if (needToBeOmitted) {
      return acc;
    }

    if (Array.isArray(value) && key === "quickFilters") {
      return acc + value.length;
    }

    if (Array.isArray(value)) {
      const count = value.length > 0 ? 1 : 0;

      return acc + count;
    }

    return acc + 1;
  }, 0);
}
