import { Button } from "@volt_developers/components";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { logoutRoute } from "modules/auth/routes/logout";

import { TEST_IDS } from "../../constants/testIds";
import { TRANSLATION_KEYS } from "../../constants/TranslationKeys";

export function LogoutButton() {
  const { t } = useTranslation("userSettings");
  const history = useHistory();

  const onClick = () => {
    history.push(logoutRoute.path);
  };

  return (
    <Button.Wrapper
      color="secondary"
      data-testid={TEST_IDS.termsAndConditionsLogoutButton}
      sx={{ width: "100%" }}
      variant="outlined"
      onClick={onClick}
    >
      <Button.Text>{t(TRANSLATION_KEYS.logout)}</Button.Text>
    </Button.Wrapper>
  );
}
