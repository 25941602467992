import { styled, tooltipClasses } from "@mui/material";
import { Button, Tooltip } from "@volt_developers/components";
import { useTranslation } from "react-i18next";

import { TEST_IDS } from "../../constants/testIds";
import { TRANSLATION_KEYS } from "../../constants/TranslationKeys";

import type { TooltipProps } from "@volt_developers/components";

interface AgreeAndAccessButtonProps {
  isDisabled: boolean;
  isLoading: boolean;
  onSubmit: VoidFunction;
}

export function AgreeAndAccessButton({
  isDisabled,
  isLoading,
  onSubmit,
}: AgreeAndAccessButtonProps) {
  const { t } = useTranslation("userSettings");

  if (isDisabled) {
    return (
      <StyledTooltip title={t(TRANSLATION_KEYS.pleaseMakeSureThat)} arrow>
        <span style={{ width: "100%" }}>
          <Button.Wrapper
            color="primary"
            data-testid={TEST_IDS.termsAndConditionsAcceptButton}
            isLoading={isLoading}
            onClick={onSubmit}
            disabled
            fullWidth
          >
            <Button.Text>{t(TRANSLATION_KEYS.agreeAndAccess)}</Button.Text>
          </Button.Wrapper>
        </span>
      </StyledTooltip>
    );
  }

  return (
    <span style={{ width: "100%" }}>
      <Button.Wrapper
        color="primary"
        data-testid={TEST_IDS.termsAndConditionsAcceptButton}
        isLoading={isLoading}
        onClick={onSubmit}
        fullWidth
      >
        <Button.Text>{t(TRANSLATION_KEYS.agreeAndAccess)}</Button.Text>
      </Button.Wrapper>
    </span>
  );
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
  },
});
