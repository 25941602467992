import { workflowsRoutes } from "modules/workflows/routes";

import type { Route } from "../RouteModel";

export const administrationRoutes: Route = {
  // title for this path is defined in children routes
  path: "/administration",
  showChildrenInSidebar: true,
  children: [workflowsRoutes],
};
