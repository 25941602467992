import { getRouteWithPersistedFilterParams } from "modules/common/utils/getRouteWithFilterParams/getRouteWithFilterParams";

import {
  DEFAULT_FILTERS_CONFIGURATION,
  FILTERS_PERSISTED_KEY,
} from "./Filters";

import type { UUID } from "utils";

const BASE_URL = "/configuration/applications";

export const PAGE_URLS = {
  create: `${BASE_URL}/create`,
  details: `${BASE_URL}/:id`,
  list: BASE_URL,
  getList: () =>
    getRouteWithPersistedFilterParams(
      PAGE_URLS.list,
      FILTERS_PERSISTED_KEY,
      DEFAULT_FILTERS_CONFIGURATION
    ),
  notificationsSettingsNew: `${BASE_URL}/:id/payment-notifications/edit`,
  notificationsSettingsNewRedirect: `${BASE_URL}/:id/notifications/settings`,
  paymentNotifications: `${BASE_URL}/:id/payment-notifications`,
  verifyNotifications: `${BASE_URL}/:id/verify-notifications`,
  verifyNotificationsSettingsNew: `${BASE_URL}/:id/verify-notifications/edit`,
  verifyNotificationsSettingsNewRedirect: `${BASE_URL}/:id/notifications/settings`,
  getDetails: (id: UUID) => `${BASE_URL}/${id}`,
  getEdit: (id: UUID) => `${BASE_URL}/${id}/edit`,
  getLinkToApplicationDetails: (id: UUID) => `${BASE_URL}/${id}`,
  getLinkToApplicationEdit: (id: UUID) => `${BASE_URL}/${id}/edit`,
  getLinkToNewDetails: (id: UUID) => `${BASE_URL}/${id}`,
  getLinkToNotificationsSettingsNew: (id: UUID) =>
    `${BASE_URL}/${id}/payment-notifications/edit`,
  getLinkToVerifyNotifications: (id: UUID) =>
    `${BASE_URL}/${id}/verify-notifications`,
  getLinkToVerifyNotificationsSettingsNew: (id: UUID) =>
    `${BASE_URL}/${id}/verify-notifications/edit`,
  getLinkToPaymentNotifications: (id: UUID) =>
    `${BASE_URL}/${id}/payment-notifications`,
  getNotificationsSettings: (id: UUID) =>
    `${BASE_URL}/${id}/notifications/settings`,
} as const;
