import { getPersistedFilterKey } from "modules/common/hooks/usePersistedFilters";

import type { ALL_TAG_TYPE } from "../../common/constants/Filters";
import type { BlockRuleType } from "../models/BlockRules";

export const FILTERS_KEYS = {
  TYPES: "types",
  STATUS: "status",
} as const;

export const RULE_STATUSES = ["ACTIVE", "INACTIVE"] as const;

export type RuleStatus = (typeof RULE_STATUSES)[number];

export type BlocklistFilters = {
  [FILTERS_KEYS.TYPES]: (ALL_TAG_TYPE | BlockRuleType)[];
  [FILTERS_KEYS.STATUS]: (ALL_TAG_TYPE | RuleStatus)[];
};

export const DEFAULT_FILTERS_CONFIGURATION = {
  [FILTERS_KEYS.TYPES]: {
    array: true as const,
    defaultValue: [],
  },
  [FILTERS_KEYS.STATUS]: {
    array: true as const,
    defaultValue: [],
  },
};

export const DEFAULT_FILTERS = {
  [FILTERS_KEYS.TYPES]: [],
  [FILTERS_KEYS.STATUS]: [],
};

export const INITIAL_FILTERS_CONFIGURATION = {
  ...DEFAULT_FILTERS_CONFIGURATION,
};

export const BLOCKLIST_FILTERS_PERSISTED_KEY =
  getPersistedFilterKey("blocklist");
