import { Card as VoltCard } from "@volt_developers/components";

import { CardDescriptionSkeleton } from "./CardDescriptionSkeleton";
import { CardHeaderSkeleton } from "./CardHeaderSkeleton";
import { CardItemLoadable } from "./CardItemLoadable";
import { CardItemSkeleton } from "./CardItemSkeleton";
import { EnvironmentBody } from "./EnvironmentBody";

export const Card = {
  ...VoltCard,
  DescriptionSkeleton: CardDescriptionSkeleton,
  HeaderSkeleton: CardHeaderSkeleton,
  ItemSkeleton: CardItemSkeleton,
  ItemLoadable: CardItemLoadable,
  EnvironmentBody,
};
