import { Skeleton } from "@mui/material";
import { Card } from "@volt_developers/components";
import { getSpacing } from "@volt_developers/foundations";

interface CardItemLoadableProps extends Card.ItemComponentProps {
  isLoading?: boolean;
}

export function CardItemLoadable({
  isLoading,
  ...other
}: CardItemLoadableProps) {
  if (isLoading) {
    return (
      <Card.Item {...other}>
        <Skeleton sx={{ lineHeight: getSpacing(6), width: "50%" }} />
      </Card.Item>
    );
  }

  return <Card.Item {...other} />;
}
