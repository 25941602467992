import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useUpdateEffect } from "react-use";

import type { Nullable, Scalar } from "utils/types";

import type { ParamDefinition, Params } from "../useQueryString";

export type FilterType = Record<string, Nullable<Scalar | Scalar[]>>;

export type DefaultFilterConfiguration<Filters extends Params = FilterType> = {
  [key in keyof Filters]: ParamDefinition<Filters[key]>;
};

export interface UseGetUrlFiltersParams<Filters extends Params> {
  defaultFilters: Partial<Filters>;
  filtersConfiguration: DefaultFilterConfiguration;
}

export function useGetUrlFilters<Filters extends Params>({
  defaultFilters,
  filtersConfiguration,
}: UseGetUrlFiltersParams<Filters>) {
  const { search } = useLocation();
  const [urlFilters, setUrlFilters] = useState(() =>
    Object.assign(
      {},
      defaultFilters,
      getFilters<Filters>(filtersConfiguration, search)
    )
  );

  useUpdateEffect(() => {
    const urlFiltersValue = getFilters<Filters>(filtersConfiguration, search);

    setUrlFilters({ ...defaultFilters, ...urlFiltersValue });
  }, [search]);

  return urlFilters;
}

function getFilters<Filters extends Params>(
  filtersConfiguration: DefaultFilterConfiguration,
  search: string
) {
  const params = new URLSearchParams(search);

  return Object.keys(filtersConfiguration).reduce((urlFilters, filterKey) => {
    const paramValue = params.get(filterKey);
    const key = filterKey as keyof typeof filtersConfiguration;

    if (!paramValue) {
      return urlFilters;
    }

    const { array: isArray, parser } = filtersConfiguration[key];
    const value = isArray ? paramValue.split(",") : paramValue;
    // TS can't calculate which type will be here, this work in useQueryString without errors because of any in parameters
    const parsedValue = parser ? parser(value as string & string[]) : value;

    return Object.assign(urlFilters, {
      [filterKey]: parsedValue,
    });
  }, {} as Filters);
}
