import type { ObjectValuesAsTypes } from "utils/types";

export const BLOCK_RULE_TYPE_BANK = "BANK";
export const BLOCK_RULE_TYPE_FINGERPRINT = "DEVICE_FINGERPRINT";
export const BLOCK_RULE_TYPE_DOCUMENT_ID = "PAYER_DOCUMENT_ID";
export const BLOCK_RULE_TYPE_MAIL = "EMAIL";
export const BLOCK_RULE_TYPE_IBAN = "IBAN";
export const BLOCK_RULE_TYPE_IP_ADDRESS = "IP";
export const BLOCK_RULE_TYPE_PAYER = "PAYER_ID";

/**
 * @deprecated Use CONTROL_LIST_RULE_TYPES instead
 * @todo change it when redesign blocklist
 */
export const BLOCK_RULE_ALL_TYPES = [
  BLOCK_RULE_TYPE_BANK,
  BLOCK_RULE_TYPE_FINGERPRINT,
  BLOCK_RULE_TYPE_DOCUMENT_ID,
  BLOCK_RULE_TYPE_MAIL,
  BLOCK_RULE_TYPE_IBAN,
  BLOCK_RULE_TYPE_IP_ADDRESS,
  BLOCK_RULE_TYPE_PAYER,
] as const;

export const RISK_RULE_PERIOD_DAY = "day";
export const RISK_RULE_PERIOD_HOUR = "hour";
export const RISK_RULE_PERIOD_MINUTE = "minute";

export const RISK_RULE_PERIOD_ALL = [
  RISK_RULE_PERIOD_DAY,
  RISK_RULE_PERIOD_HOUR,
  RISK_RULE_PERIOD_MINUTE,
] as const;

export type RISK_RULE_PERIOD_TYPES =
  | typeof RISK_RULE_PERIOD_DAY
  | typeof RISK_RULE_PERIOD_HOUR
  | typeof RISK_RULE_PERIOD_MINUTE;

export const RISK_RULE_OPERATOR_GREATER_THAN = ">";
export const RISK_RULE_OPERATOR_LESS_THAN = "<";

export const RISK_RULE_OPERATOR_ALL = [
  RISK_RULE_OPERATOR_GREATER_THAN,
  RISK_RULE_OPERATOR_LESS_THAN,
] as const;

export type RISK_RULE_OPERATORS =
  | typeof RISK_RULE_OPERATOR_GREATER_THAN
  | typeof RISK_RULE_OPERATOR_LESS_THAN;

export const RISK_RULE_TYPE_PAYMENT_AMOUNT = "TRANSACTION_AMOUNT";
export const RISK_RULE_TYPE_VOLUME_OF_TRANSACTIONS = "VOLUME_OF_TRANSACTIONS";
export const RISK_RULE_TYPE_NUMBER_OF_PAYMENTS = "NUMBER_OF_TRANSACTIONS";

export const RISK_RULE_ALL_TYPES = [
  RISK_RULE_TYPE_PAYMENT_AMOUNT,
  RISK_RULE_TYPE_VOLUME_OF_TRANSACTIONS,
  RISK_RULE_TYPE_NUMBER_OF_PAYMENTS,
] as const;

export const RISK_RULE_INCLUDE_FILTER = "include";
export const RISK_RULE_EXCLUDE_FILTER = "exclude";

export const RISK_RULE_FILTER_CONDITION_ALL = [
  RISK_RULE_INCLUDE_FILTER,
  RISK_RULE_EXCLUDE_FILTER,
] as const;

export enum COMPARISON_TYPES {
  NONE,
  TEXT,
  SELECT,
}

export const VOLT_ADMIN = "Volt Administrator";

export const CONTROL_LIST_RULE_TYPES = {
  BANK: "BANK",
  FINGERPRINT: "DEVICE_FINGERPRINT",
  DOCUMENT_ID: "PAYER_DOCUMENT_ID",
  EMAIL: "EMAIL",
  IBAN: "IBAN",
  IP: "IP",
  PAYER_ID: "PAYER_ID",
} as const;

export type ControlListRuleType = ObjectValuesAsTypes<
  typeof CONTROL_LIST_RULE_TYPES
>;
