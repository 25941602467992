import { COMMON_TRANSLATION_KEYS } from "constants/commonTranslationsKeys";
import { ACTION_DETAILS, ACTION_LIST } from "models/role/action";
import { SUBJECT_CASH_MANAGEMENT_SETTLEMENT } from "models/role/subject";
import { lazyRetry } from "utils/lazyRetry";

import { PAGE_URLS } from "../constants/PageUrls";

import type { Route } from "routes/RouteModel";

const List = lazyRetry(
  () => import("../pages/settlements/List"),
  "SettlementsList"
);

const Details = lazyRetry(
  () => import("../pages/settlements/Details"),
  "SettlementsDetails"
);

export const settlementsRoutes: Route[] = [
  {
    title: [COMMON_TRANSLATION_KEYS.settlements, COMMON_TRANSLATION_KEYS.list],
    path: PAGE_URLS.settlements,
    component: List,
    can: {
      do: ACTION_LIST,
      on: SUBJECT_CASH_MANAGEMENT_SETTLEMENT,
    },
  },
  {
    title: [
      COMMON_TRANSLATION_KEYS.settlements,
      COMMON_TRANSLATION_KEYS.details,
    ],
    path: PAGE_URLS.settlementDetails,
    component: Details,
    can: {
      do: ACTION_DETAILS,
      on: SUBJECT_CASH_MANAGEMENT_SETTLEMENT,
    },
  },
];
